/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, {
  useCallback, useEffect, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { appInsights } from 'appInsights';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { styled } from '@material-ui/core/styles';
import {
  TRACKING_EVENTS, NAME_FORMAT, EMAIL_FORMAT,
  PASSWORD_FORMAT,
} from 'Constants';
import { KnBoldSectionHeader, KnSectionHeader } from 'components/Typography';
import KnTextField from 'components/TextField';
import KnButton from 'components/Button';
import healthSystemActions from 'redux/actions/healthSystemActions';
import userRoleActions from 'redux/actions/userRoleActions';
import { phoneNumberValidator } from 'utils/customFieldValidators';
import KnValidatedTextField from 'components/ValidatedTextField';

const KnBoldSectionHeaderSignUp = styled(KnBoldSectionHeader)({
  marginBottom: 24,
});

const initialRegisterInfo = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  password: '',
  confirmPassword: '',
  healthSystem: '',
  userRole: '',
};

const SignUpStep = ({ submitDisabled, onSuccess, stepsData }) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const {
    data: HSProviders,
    loading: loadingHSProviders,
  } = useSelector((state) => state.healthSystem);
  const {
    data: UserRoles,
    loading: loadingUserRoles,
  } = useSelector((state) => state.userRole);
  const {
    handleSubmit, errors, control, triggerValidation,
  } = useForm({
    mode: 'onChange',
    defaultValues: Object.keys(stepsData).length ? stepsData : initialRegisterInfo,
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    appInsights.trackEvent({ name: TRACKING_EVENTS.viewRegisterPage });
    dispatch(healthSystemActions.fetch());
    dispatch(userRoleActions.fetch());
  }, [dispatch]);

  /** We can cache the validation rules, as they should change only if the
   * translation is updated so that the messages are in the correct language.
   */
  const phoneNumberValidationRules = useMemo(() => ({
    validate: {
      phoneNumber: (value) => (
        phoneNumberValidator(value)
          ? true
          : translate('FIELD_VALIDATION_MESSAGES.phone.format')
      ),
    },
  }), [translate]);

  const handleDropDownChange = useCallback(([{ target: { value } }]) => value, []);

  const onPasswordBlur = useCallback(() => {
    /** On password blur, if there is a value in the confirm password,
     * trigger the validation of confirm password field manually,
     * in case a mismatch password error was fixed.
     */
    if (control.getValues('confirmPassword')) {
      triggerValidation('confirmPassword');
    }
  }, [control, triggerValidation]);

  /** This function will be called by handleSubmit with form data
   * only when the data is valid. We can make the sign up call now.
   */
  const onSubmit = (formData, e) => {
    e.preventDefault();
    onSuccess(formData);
  };

  return (
    <>
      <Typography variant="h6" component={KnBoldSectionHeaderSignUp}>
        {translate('REGISTER.registrationTitle')}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography
          variant="h6"
          component={KnSectionHeader}
          data-testid="personal-info-subtitle"
        >
          {translate('REGISTER.personalInfoSubtitle')}
        </Typography>
        <KnValidatedTextField
          Component={KnTextField}
          name="firstName"
          control={control}
          errors={errors}
          required
          trimSpaces
          format={NAME_FORMAT}
          maxLength={30}
        />
        <KnValidatedTextField
          Component={KnTextField}
          name="lastName"
          control={control}
          errors={errors}
          required
          trimSpaces
          format={NAME_FORMAT}
          maxLength={30}
        />
        <KnValidatedTextField
          Component={KnTextField}
          name="email"
          control={control}
          errors={errors}
          required
          trimSpaces
          format={EMAIL_FORMAT}
          maxLength={128}
        />
        <KnValidatedTextField
          Component={KnTextField}
          name="phone"
          control={control}
          errors={errors}
          required
          trimSpaces
          maxLength={256}
          rules={phoneNumberValidationRules}
        />
        <Typography paragraph>{translate('REGISTER.phoneHint')}</Typography>
        <Typography
          variant="h6"
          component={KnSectionHeader}
          data-testid="password-subtitle"
        >
          {translate('REGISTER.passwordSubtitle')}
        </Typography>
        {/* Using a hidden input, to stop the browser from completing the phone number input
          with a stored email value. See KK-657 */}
        <KnValidatedTextField
          Component={KnTextField}
          name="username"
          control={control}
          errors={errors}
          defaultValue="whatever"
          hidden
        />
        <KnValidatedTextField
          Component={KnTextField}
          name="password"
          control={control}
          errors={errors}
          type="password"
          required
          format={PASSWORD_FORMAT}
          notMatchField="email"
          maxLength={256}
          onBlur={onPasswordBlur}
        />
        <KnValidatedTextField
          Component={KnTextField}
          name="confirmPassword"
          control={control}
          errors={errors}
          type="password"
          required
          matchField="password"
          maxLength={256}
        />
        <Typography paragraph>{translate('REGISTER.passwordHint')}</Typography>
        <Typography
          variant="h6"
          component={KnSectionHeader}
          data-testid="health-system-subtitle"
        >
          {translate('REGISTER.healthSystemSubtitle')}
        </Typography>
        <KnValidatedTextField
          Component={KnTextField}
          name="healthSystem"
          control={control}
          errors={errors}
          select
          required
          maxLength={256}
          onChange={handleDropDownChange}
          disabled={loadingHSProviders}
        >
          {
            HSProviders.map((option, index) => (
              <MenuItem
                key={option.id}
                value={option.id}
                data-testid={`health-system-option-${index + 1}`}
              >
                {option.name}
              </MenuItem>
            ))
          }
        </KnValidatedTextField>

        <KnValidatedTextField
          Component={KnTextField}
          name="userRole"
          control={control}
          errors={errors}
          select
          required
          maxLength={256}
          onChange={handleDropDownChange}
          disabled={loadingUserRoles}
        >
          {
            UserRoles.map((option, index) => (
              <MenuItem
                key={option.id}
                value={option.id}
                data-testid={`health-system-option-${index + 1}`}
              >
                {option.name}
              </MenuItem>
            ))
           }
        </KnValidatedTextField>

        <Box
          pt={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <KnButton
            variant="text"
            type="button"
            route="/"
            data-testid="login-link-button"
          >
            {translate('REGISTER.backToLogin')}
          </KnButton>
          <KnButton
            data-testid="register-button"
            disabled={submitDisabled}
          >
            {translate('REGISTER.submitButton')}
          </KnButton>
        </Box>
      </form>
    </>
  );
};

SignUpStep.propTypes = {
  submitDisabled: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  stepsData: PropTypes.shape({}).isRequired,
};

export default SignUpStep;
