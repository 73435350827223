/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import { capFirstCharOnly, serverDateToGMT } from 'utils/utils';
import {
  REGIMEN_STATUS,
  SEIZURE_USER_RESPONSES,
  SURVEY_TYPES,
  THRESHOLD_EVENT_TYPES,
} from 'Constants';
import refDataService, { REFERENCE_DATA_TYPES } from 'services/referenceDataService';

const mapSeizureUserResponse = (response) => {
  switch (response && response.toLowerCase()) {
    case SEIZURE_USER_RESPONSES.yes:
      return true;

    case SEIZURE_USER_RESPONSES.no:
      return false;

    case SEIZURE_USER_RESPONSES.notSure:
      return null;

    /** Let's hope we'll never be in this case. */
    default:
      return null;
  }
};

const mapServerPatientInfoToLocal = (patientInfo) => ({
  patientId: patientInfo.id,
  linkId: (patientInfo.currentLinkId) ? patientInfo.currentLinkId : patientInfo.physicianId,
  accountNumber: (patientInfo.accountNumber) ? patientInfo.accountNumber : 'N/A',
  firstName: patientInfo.firstName,
  lastName: patientInfo.lastName,
  isVerified: patientInfo.isVerified,
  email: (patientInfo.email) ? patientInfo.email : 'N/A',
  dob: (patientInfo.dob) ? serverDateToGMT(patientInfo.dob) : 'N/A',
  lastActive: patientInfo.lastActive && new Date(patientInfo.lastActive),
  gender: patientInfo.gender && patientInfo.gender.toLowerCase(),
  phoneNumber: patientInfo.phoneNumber,
  lastSeizure: patientInfo.lastReportedSeizure && new Date(patientInfo.lastReportedSeizure),
  diagnosedSince: capFirstCharOnly(
    _.get(patientInfo, 'diagnosedWithEpilepsyDuration.value'),
  ),
  timeZone: patientInfo.timeZone,
  isDeleted: patientInfo.isDeleted,
  thresholdMet: patientInfo.latestThresholdDate && new Date(patientInfo.latestThresholdDate),
  latestRegimenStatus: patientInfo.latestRegimenStatus,
});

const mapServerPatientsListToLocal = (data) => data.map((link, index) => ({
  dateOfRequest: new Date(link.dateOfRequest),
  dateOfDeactivation: new Date(link.dateOfDeactivation),
  /** patient data */
  ...mapServerPatientInfoToLocal(link.patientData),
  /** For unlinked patients there's no linkId,
   * let's use index instead, as it's needed for 'key' value for the rows of the table
   * IMPORTANT: to have linkId added after ...mapServerPatientInfoToLocal()
   *  to avoid overwriting the linkId field,
   *  as at this point BE doesn't return linkId value in patient data for patient lists
   */
  linkId: link.id || index,
  physician: `${link.hcpFirstName} ${link.hcpLastName}`,
  physicianId: link.physicianId,
  healthSystemId: link.healthSystemId,
  healthSystemName: link.healthSystemName,
}));

const mapServerInvitedPatientsListToLocal = (data) => data.map((link, index) => ({
  dateInvited: link.dateInvited ? new Date(link.dateInvited) : new Date(),
  dob: link.dob === 'NA' ? 'NA' : new Date(link.dob),
  linkId: link.id || index,
  physician: link.physician,
  physicianId: link.physicianId,
  firstName: link.firstName || 'NA',
  lastName: link.lastName || 'NA',
  email: link.email || 'NA',
  latestRegimenStatus: link.titrationStatus || REGIMEN_STATUS.completed,
  selected: link.selected,
  patientId: link.id,
  healthSystemId: link.healthSystemId,
  healthSystemName: link.healthSystemName,
}));

const mapServerPatientSeizuresToLocal = (data) => data.map((item) => {
  const seizure = {
    id: item.id,
    convulsions: mapSeizureUserResponse(item.experiencedConvulsions),
    lossOfConsciousness: mapSeizureUserResponse(item.lostConsciousness),
    duration: item.duration,
    notes: item.notes,
    timePeriod: item.timePeriod,
    eventDetail: item.eventDetail,
    eventType: item.eventType,
  };

  /** Consider only the date part from the date received from the server
   * Add safe code, in case current 'timestamp' field changes to 'date'
   */
  const dateFromServer = item.timestamp || item.date;
  const dateOnly = dateFromServer.length > 10 ? dateFromServer.substring(0, 10) : dateFromServer;
  const time = (item.timestamp) ? new Date(item.timestamp).toTimeString().split(' ')[0] : '';
  seizure.date = serverDateToGMT(dateOnly);
  seizure.time = time;

  if (item.recoveryTime) {
    seizure.recoveryTime = refDataService.getValue(
      REFERENCE_DATA_TYPES.seizureRecoveryTime,
      item.recoveryTime.id,
    );
  }

  if (item.triggers) {
    seizure.triggers = _.map(item.triggers, (trigger) => (
      refDataService.getValue(
        REFERENCE_DATA_TYPES.seizureTrigger,
        trigger.id,
      )
    ));
  }

  if (_.isBoolean(item.didGoToER)) {
    seizure.didGoToER = item.didGoToER;
  }

  if (_.isBoolean(item.didUseRescueMedication)) {
    seizure.didUseRescueMedication = item.didUseRescueMedication;
  }

  if (_.has(item, 'timePeriod')) {
    seizure.timePeriod = item.timePeriod;
  }

  return seizure;
});

const mapServerPatientSymptomsToLocal = (data) => data.map((symptom) => {
  const symptomNameId = _.get(symptom, 'sideEffect.id');

  return {
    id: symptom.id,
    date: serverDateToGMT(symptom.date),
    severity: symptom.severity,
    lastedMoreThanADay: symptom.lastedMoreThanADay,
    notes: symptom.notes,
    symptomNameId,
    symptomNameValue: refDataService.getValue(REFERENCE_DATA_TYPES.sideEffect, symptomNameId),
    sideEffect: symptom.sideEffect,
    durationInHour: symptom.duration,
    startTimestamp: symptom.startTimestamp,
  };
});

const mapServerPatientMedicationAdherenceToLocal = (data) => data.map((med) => {
  const medicationNameId = _.get(med, 'medication.id');

  return {
    date: serverDateToGMT(med.date),
    medicationNameId,
    medicationNameValue: refDataService.getValue(
      REFERENCE_DATA_TYPES.medications,
      medicationNameId,
    ).name,
    taken: med.taken,
  };
});

const mapServerPatientSurveyResultsToLocal = (data) => data.map((result) => ({
  id: result.id,
  type: SURVEY_TYPES[result.type],
  score: result.score,
  maxScore: result.maxScore,
  date: new Date(result.timestamp),
}));

const mapServerPatientIDSurveyResultsToLocal = (data) => data.map((result) => ({
  id: result.id,
  assignedByHcpId: result.assignedByHcpId,
  assignedDate: result.assignedDate,
  endDate: result.endDate,
  frequency: result.frequency,
  patientUserId: result.patientUserId,
  startDate: result.startDate,
  status: result.status,
  surveyType: result.surveyType,
  surveyDates: result.surveyDates,
  surveyId: result.surveyId,
  takenSurveys: result.takenSurveys,
  totalScore: result.totalScore,
}));

const mapServerPatientAllSurveyResultsToLocal = (data) => data.map((result) => ({
  id: result.id,
  title: result.title,
  description: result.description,
  questions: result.questions,
  totalScore: result.totalScore,
  ownedBy: result.ownedBy,
  surveyPdfUrl: result.surveyPdfUrl,
  surveyType: result.surveyType,
  isActive: result.isActive,
  surveyId: result.surveyId,
  createdAt: result.createdAt,
  takenSurveys: result.takenSurveys,
  updatedAt: result.updatedAt,
  assignedSurvey: (result.assignedSurvey) ? result.assignedSurvey : null,
  assignedSurveys: result.assignedSurveys,
}));

const mapServerPatientEventsToLocal = (data) => data.map((result, index) => ({
  id: result.id || index,
  type: THRESHOLD_EVENT_TYPES[result.eventType],
  /**
   * Here, we don't want local timezone to be applied,
   *  so normally, we would use serverDateToGMT(), as value comes in YYYY-MM-DD format,
   * but we still use new Date(), as we compare 'date' with other achievement dates (when sorting),
   * eg. with 'date' from mapServerPatientSurveyResultsToLocal() above
   * Timezone will be handled at display time (see translation key)
   */
  date: new Date(result.date),
  isSeen: result.hcpSeen,
}));

export default {
  mapServerPatientInfoToLocal,
  mapServerPatientSeizuresToLocal,
  mapServerPatientsListToLocal,
  mapServerPatientSymptomsToLocal,
  mapServerPatientMedicationAdherenceToLocal,
  mapServerPatientSurveyResultsToLocal,
  mapServerPatientIDSurveyResultsToLocal,
  mapServerPatientAllSurveyResultsToLocal,
  mapServerPatientEventsToLocal,
  mapServerInvitedPatientsListToLocal,
};
