/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { useState, useEffect } from 'react';

/** Key definitions for session items
 * Note: All of these are cleared on login/logout
 */
export const SESSION_STORAGE_KEYS = {
  physicianFilter: 'physicianFilter',
  patientsListTab: 'patientsListTab',
};

/**
 * React hook wrap for sessionStorage
 */
const useSessionStorage = (key, defaultValue = undefined) => {
  /** state to store value,
   * initialized with the value stored in session storage
   */
  const [storedValue, setStoredValue] = useState(sessionStorage.getItem(key) || defaultValue);

  /** when state value is changed,
   * save it in session storage as well
   */
  useEffect(() => {
    sessionStorage.setItem(key, storedValue);
  }, [key, storedValue]);

  return [storedValue, setStoredValue];
};

/**
 * Clears all sessions keys used by the app
 */
export const clearAppSessionKeys = () => {
  Object.keys(SESSION_STORAGE_KEYS).forEach((key) => sessionStorage.removeItem(key));
};

export default useSessionStorage;
