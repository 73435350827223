/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import { KnActionLink } from 'components/Link';
import KnEditIcon from 'components/icons/EditIcon';
import KnDeleteIcon from 'components/icons/DeleteIcon';
import KnDuplicateIcon from 'components/icons/DuplicateIcon';
import KnIconButton from 'components/IconButton';
import { TITRATIONS_CONTEXT, REGIMEN_STATUS } from 'Constants';
import { format } from 'date-fns';
import palette from 'styles/colors';
import { styled } from '@material-ui/core';
import {
  KnTitrationNameContainer,
  KnTitrationMedsContainer,
  KnTitrationActionsContainer,
} from '../styles';
import TitrationDateInfo from './TitrationDateInfo';

const { plainGrey } = palette;

const KnText = styled(Typography)({ width: '440px', color: plainGrey, fontSize: '15px' });

const KnTitrationItemInfo = (props) => {
  const {
    titration, context, actions, expanded,
  } = props;
  const {
    status, startedAt, deactivatedAt, completedAt, titrationUpdateDate,
  } = titration;
  const { t: translate } = useTranslation();
  const [calculatedDate, setCalculatedDate] = useState(1);

  const onAction = useCallback((e, actionCallback, titrationData) => {
    e.stopPropagation();
    if (actionCallback) {
      actionCallback(titrationData);
    }
  }, []);

  useEffect(() => {
    const date1 = new Date(startedAt);
    const date2 = titrationUpdateDate
      ? new Date(titrationUpdateDate) : deactivatedAt || completedAt;
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    setCalculatedDate(diffDays);
  }, [deactivatedAt, startedAt, completedAt, titrationUpdateDate, setCalculatedDate]);

  const titrationName = useMemo(() => {
    switch (context) {
      case TITRATIONS_CONTEXT.assignedRegimen:
        return translate('PATIENT_RECORD.medicationRegimen.assignedRegimenTitle');
      case TITRATIONS_CONTEXT.pastRegimen:
        if (titration.startedAt) {
          return translate(
            'PATIENT_RECORD.medicationRegimen.pastRegimenTitle',
            {
              startDate: titration.startedAt,
              endDate: titration.titrationUpdateDate ? format(new Date(titration.titrationUpdateDate), 'MM/dd/yyyy') : titration.deactivatedAt || titration.completedAt,
            },
          );
        }
        return translate('PATIENT_RECORD.medicationRegimen.neverStartedRegimenTitle');
      default:
        return titration.name;
    }
  }, [titration, context, translate]);

  return (
    <>
      <Box display="flex" alignItems="baseline" flex={1}>
        <KnTitrationNameContainer>
          <Typography variant="h6">{titrationName}</Typography>
          {(expanded && startedAt && status !== 'completed') && (
            <KnText>
              {translate(
                'PATIENT_RECORD.medicationRegimen.incompletePastRegimenText', { data: calculatedDate },
              )}
            </KnText>
          )}
          {(expanded && !startedAt && context !== TITRATIONS_CONTEXT.titrationsManagement) && (
            <KnText>
              {translate('PATIENT_RECORD.medicationRegimen.neverStartedRegimenTitleMedication')}
            </KnText>
          )}
        </KnTitrationNameContainer>
        <KnTitrationMedsContainer>
          <Box>
            <TitrationDateInfo titration={titration} context={context} />
          </Box>
        </KnTitrationMedsContainer>
        {context === TITRATIONS_CONTEXT.titrationsManagement
          && (
            <KnTitrationActionsContainer>
              <KnActionLink
                data-testid="edit-titration-button"
                LhsIcon={KnEditIcon}
                onClick={(e) => onAction(e, actions.onEditTitration, titration)}
              />
              <KnActionLink
                style={{ marginLeft: 22 }}
                data-testid="duplicate-titration-button"
                LhsIcon={KnDuplicateIcon}
                onClick={(e) => onAction(e, actions.onDuplicateTitration, titration)}
              />

              <KnIconButton
                data-testid="delete-titration-button"
                Icon={KnDeleteIcon}
                onClick={(e) => onAction(e, actions.onDeleteTitration, titration)}
              />
            </KnTitrationActionsContainer>
          )}

        {(context === TITRATIONS_CONTEXT.assignPreset) && (
          <KnTitrationActionsContainer>
            <KnActionLink
              data-testid="edit-preset-before-assigning-button"
              LhsIcon={KnEditIcon}
              onClick={(e) => onAction(e, actions.onEditTitration, titration)}
            >
              {translate('ASSIGN_REGIMEN.editBeforeAssigning')}
            </KnActionLink>
          </KnTitrationActionsContainer>
        )}

        {context === TITRATIONS_CONTEXT.assignedRegimen
          && (
            <KnTitrationActionsContainer>
              {actions.onEditMedicationRegimen
                && (
                  <KnActionLink
                    data-testid="edit-medication-regimen-button"
                    LhsIcon={KnEditIcon}
                    disabled={titration.status !== REGIMEN_STATUS.notStarted}
                    onClick={(e) => onAction(e, actions.onEditMedicationRegimen, titration)}
                  >
                    {translate('GENERAL.editButton')}
                  </KnActionLink>
                )}
              {actions.onDeactivateMedicationRegimen
                && (
                  <KnIconButton
                    data-testid="deactivate-medication-regimen-button"
                    Icon={KnDeleteIcon}
                    disabled={titration.status !== REGIMEN_STATUS.notStarted}
                    onClick={(e) => onAction(e, actions.onDeactivateMedicationRegimen, titration)}
                  />
                )}
            </KnTitrationActionsContainer>
          )}

      </Box>
      {expanded && (!!titration.hcpNote || !!titration.note)
          && (
            <Box>
                {!!titration.hcpNote
                && (
                  <Box mt="24px">
                    <Box fontWeight={500} fontSize="13px" style={{ textTransform: 'uppercase' }}>
                      {translate('TITRATIONS.notesToDoctor')}
                      <span style={{ fontWeight: 500 }}>{`${translate('TITRATIONS.doctorUse')} :`}</span>
                    </Box>
                    {titration.hcpNote}
                  </Box>
                )}
                {!!titration.note
                && (
                  <Box mt="30px">
                    <Box fontWeight={500} fontSize="13px">
                      {`${translate('TITRATIONS.noteToPatient')} :`}
                    </Box>
                    {titration.note}
                  </Box>
                )}
            </Box>
          )}

    </>
  );
};

KnTitrationItemInfo.propTypes = {
  titration: PropTypes.shape().isRequired,
  context: PropTypes.oneOf(
    [TITRATIONS_CONTEXT.assignPreset, TITRATIONS_CONTEXT.titrationsManagement,
      TITRATIONS_CONTEXT.assignedRegimen, TITRATIONS_CONTEXT.pastRegimen],
  ).isRequired,
  actions: PropTypes.shape(),
  expanded: PropTypes.bool.isRequired,
};

KnTitrationItemInfo.defaultProps = {
  actions: {},
};

export default KnTitrationItemInfo;
