/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import React, {
  useCallback, useEffect, useState, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, styled, MenuItem } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import KnButton from 'components/Button';
import KnCloseIcon from 'components/icons/CloseIcon';
import KnIconButton from 'components/IconButton';
import { withKeyNamespace } from 'utils/utils';
import KnValidatedTextField from 'components/ValidatedTextField';
import { KnContrastTextField } from 'components/TextField';
import { MEDICATION_STRENGTH_TYPE, TITRATION_STRENGTH_FORMAT } from 'Constants';
import InputAdornment from '@material-ui/core/InputAdornment';
import palette from 'styles/colors';

const i18nKey = withKeyNamespace('TITRATIONS.titrationBuilder.setStrengthDialog');
const closeIconColor = palette.secondary;

const KnButtonsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',

  '& > *:first-child': {
    marginBottom: theme.spacing(1),
  },
}));

const KnInputWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',

  '& > *:first-child': {
    width: theme.spacing(16),
    marginRight: theme.spacing(2),
    paddingBottom: 0,
  },

  '& > *:nth-child(2)': {
    paddingTop: theme.spacing(2.5),
  },
}));

const KnContrastTextFieldChange = styled(KnContrastTextField)({
  marginLeft: '10px',
  marginBottom: '20px',
  position: 'relative',
  width: '344px',
});

const KnValidatedTextFieldChnage = styled(KnValidatedTextField)(({ lableSelected }) => ({
  opacity: lableSelected ? 1 : 0,
  pointerEvents: lableSelected ? 'all' : 'none',
  marginBottom: '-12px',
  top: '10px',
  left: '10px',
  width: '344px',
  position: 'relative',
}));

const KnStrengthDialog = ({
  open,
  onSubmit,
  onClose,
  inputData,
}) => {
  const {
    medicationStrength,
    medicationType,
    medicationTypeUnit,
  } = inputData;
  const { t: translate } = useTranslation();
  const {
    handleSubmit, errors, control, formState, getValues, triggerValidation, watch,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const capitalizeFirstLetter = (str) => {
    if (!str) return str;
    // converting first letter to uppercase
    const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

    return capitalized;
  };
  const [lable, setLable] = useState(medicationTypeUnit || '');
  const [lableSelected, setLableSelected] = useState(false);
  const [clable, setCLable] = useState('Medication');
  const [suspensionFilter, setSuspensionFilter] = useState(capitalizeFirstLetter(medicationType));


  /** We have this handle for the 'Set tablet strength' button,
   * as button is outside the form component (due to the Dialog layout)
   */
  useEffect(() => {
    if (getValues('medicationStrength')) setLableSelected(true);
    if (medicationType) setLableSelected(true);
  }, [formState, getValues, triggerValidation, watch, medicationType]);

  const handleChange = useCallback((e) => {
    const val = e.target.value;
    if (Object.values(MEDICATION_STRENGTH_TYPE).includes(val)) {
      setLable(translate(i18nKey(val === MEDICATION_STRENGTH_TYPE.suspension ? 'mgPerMl' : 'mgPerPill')));
      setCLable(val);
      setLableSelected(true);
    }
    setSuspensionFilter(val);
  }, [translate]);

  const InputProps = useMemo(() => ({
    endAdornment: (
      <InputAdornment position="end">
        {lable}
      </InputAdornment>
    ),
  }), [lable]);

  const submitStrength = useCallback(() => {
    if (!getValues('medicationStrength')) {
      /** Validation is auto-triggered onBlur,
       * but if user never touched the (initially empty) field,
       * we need to trigger validation manually
       */
      triggerValidation('medicationStrength');
    }
    /** Submit the medicationStrength value only if it is valid */
    if (formState.isValid) {
      onSubmit({ medicationStrength: Number(getValues('medicationStrength')), medicationType: suspensionFilter.toLowerCase(), medicationTypeUnit: lable });
    }
  }, [onSubmit, formState.isValid, getValues, triggerValidation, lable, suspensionFilter]);

  if (open) {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        data-testid="dialog-wrapper"
      >
        <DialogTitle data-testid="dialog-title" onClose={onClose}>
          <Box display="flex" justifyContent="space-between">
            {translate(i18nKey('title'))}

            <KnIconButton
              data-testid="delete-close-button"
              Icon={KnCloseIcon}
              iconColor={closeIconColor}
              onClick={onClose}
            />
          </Box>
        </DialogTitle>

        <DialogContentText style={{ marginTop: '-35px', marginLeft: '33px' }} data-testid="dialog-content-text">
          {translate(i18nKey('hint'))}
        </DialogContentText>
        <DialogContent data-testid="dialog-content">

          {/** We don't call handleSubmit(onSubmit) directly,
           * as we want to convert medicationStrength from string to Number first,
           * which is done by `submitStrength`
           */}
          <form onSubmit={handleSubmit(submitStrength)}>
            <KnInputWrapper style={{ flexDirection: 'column', marginLeft: '20px' }}>
              <KnContrastTextFieldChange
                name="suspensionFilter"
                disableErrorMessage
                control={control}
                errors={errors}
                select
                defaultValue={suspensionFilter}
                value={suspensionFilter}
                label={translate(i18nKey('filter'))}
                onChange={(e) => handleChange(e)}
              >
                {_.values(MEDICATION_STRENGTH_TYPE).map((type, index) => (
                  <MenuItem
                    key={type}
                    value={type}
                    data-testid={`physician-filter-option-${index + 1}`}
                  >
                    {type}
                  </MenuItem>
                ))}
              </KnContrastTextFieldChange>
              <KnValidatedTextFieldChnage
                Component={KnContrastTextField}
                disableLabel
                disableErrorMessage
                name="medicationStrength"
                control={control}
                errors={errors}
                InputProps={InputProps}
                required
                trimSpaces
                format={TITRATION_STRENGTH_FORMAT}
                notEqualTo="0"
                defaultValue={medicationStrength || ''}
                label={translate(i18nKey('lableStrength'))}
                lableSelected={lableSelected}
              />

              <FormHelperText error data-testid="strength-validation-error">
                {_.get(errors, 'medicationStrength.message', '')}
              </FormHelperText>
            </KnInputWrapper>
          </form>


        </DialogContent>
        <DialogActions data-testid="dialog-actions">
          <KnButtonsWrapper>
            <KnButton
              disabled={!lableSelected}
              onClick={submitStrength}
              data-testid="dialog-submit-button"
            >
              {translate('TITRATIONS.titrationBuilder.setStrengthDialog.setStrengthButton',
                { tablet: clable })}
            </KnButton>
            <KnButton
              variant="text"
              onClick={() => onSubmit({
                medicationStrength: undefined,
                medicationType: undefined,
                medicationTypeUnit: undefined,
              })}
              data-testid="dialog-reset-button"
            >
              {translate('TITRATIONS.titrationBuilder.setStrengthDialog.removeStrengthButtton', { tablet: clable })}
            </KnButton>
          </KnButtonsWrapper>
        </DialogActions>
      </Dialog>
    );
  }

  return null;
};

KnStrengthDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  inputData: PropTypes.shape({
    medicationStrength: PropTypes.number,
    medicationType: PropTypes.string,
    medicationTypeUnit: PropTypes.string,
  }),
};

KnStrengthDialog.defaultProps = {
  inputData: {
    medicationStrength: undefined,
    medicationType: undefined,
    medicationTypeUnit: undefined,
  },
};

export default KnStrengthDialog;
