/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@material-ui/core/styles';
import { KnBrightBox } from 'components/Content';
import InputAdornment from '@material-ui/core/InputAdornment';
import KnSearchIcon from 'components/icons/SearchIcon';
import { KnTextFieldBase } from 'components/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import palette from 'styles/colors';
import userActions from 'redux/actions/userActions';
import { composeFullName } from 'utils/utils';
import { Checkbox, ListItemText } from '@material-ui/core';
import { REGIMEN_STATUS_LIST } from '../../Constants';
import healthSystemActions from '../../redux/actions/healthSystemActions';

const KnToolbarInput = styled(KnTextFieldBase)({
  '& .MuiFilledInput-root': {
    backgroundColor: palette.white.white1,
  },
});

const KnSearchInput = styled(KnToolbarInput)({
  width: 458,
});

const KnFilterInput = styled(KnToolbarInput)(({ theme }) => ({
  width: 226,
  marginLeft: theme.spacing(1),
}));

const KnCheckbox = styled(Checkbox)({
  transform: 'scale(2)',
  color: palette.primary,
});

const KnPatientsTableToolbar = (props) => {
  const {
    onSearchTermChange,
    onPhysicianFilterChange,
    onRegimenStatusFilterChange,
    searchTerm,
    physicianFilter,
    regimenStatusFilter,
    disabled,
    isAdmin,
    healthSystemFilter,
    onHealthSystemFilterChange,
  } = props;
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const { physiciansList } = useSelector((state) => state.user);
  const { data: healthSystemList } = useSelector((state) => state.healthSystem);

  /** This adds the search icon at the end of the text field. */
  const InputProps = useMemo(() => ({
    endAdornment: (
      <InputAdornment position="end">
        <KnSearchIcon />
      </InputAdornment>
    ),
  }), []);

  useEffect(() => {
    dispatch(userActions.getPhysicians());
    if (isAdmin) {
      dispatch(healthSystemActions.fetch());
    }
  }, [dispatch, isAdmin]);

  /** Sorting be lastName and then by firstName */
  let orderedPhysicians = useMemo(() => (
    _.orderBy(
      physiciansList.data,
      [(physician) => `${physician.lastName.toLowerCase()}${physician.firstName.toLowerCase()}`],
    )
  ), [physiciansList.data]);

  /** Filter physicians if there are selected health system */
  if (healthSystemFilter.length > 0) {
    orderedPhysicians = orderedPhysicians.filter(
      (physician) => healthSystemFilter.includes(physician.healthSystemId),
    );
  }

  return (
    <KnBrightBox
      display="flex"
      justifyContent="space-between"
      py={1}
      px={2}
      mt={4}
    >
      <div>
        <KnSearchInput
          value={searchTerm}
          onChange={onSearchTermChange}
          label={translate('GENERAL.search')}
          InputProps={InputProps}
          /* eslint-disable-next-line react/jsx-no-duplicate-props */
          inputProps={{ 'data-testid': 'patients-search-input-field' }}
        />

        {isAdmin && (
        <KnFilterInput
          select
          multiple
          value={healthSystemFilter}
          onChange={onHealthSystemFilterChange}
          label={translate('HOME.filterByHealthSystem')}
          data-testid="health-system-filter-dropdown"
          renderValue={(selected) => healthSystemList.filter((hs) => selected.includes(hs.id)).map((hs) => hs.name).join(', ')}
        >
          {healthSystemList.map((healthSystemItem) => (
            <MenuItem
              key={healthSystemItem.id}
              value={healthSystemItem.id}
              data-testid={`health-system-filter-option-${healthSystemItem.id}`}
            >
              <KnCheckbox checked={healthSystemFilter.includes(healthSystemItem.id)} color="primary" />
              <ListItemText primary={healthSystemItem.name} />
            </MenuItem>
          ))}
        </KnFilterInput>
        )}

        <KnFilterInput
          select
          value={physiciansList.initialized && physicianFilter && !disabled ? physicianFilter : 0}
          label={translate('HOME.filterByPhysician')}
          onChange={onPhysicianFilterChange}
          data-testid="physician-filter-dropdown"
          disabled={disabled || (isAdmin && !healthSystemFilter.length)}
          fullMaxHeight
        >
          <MenuItem value={0} data-testid="physician-filter-option-all">
            {translate('HOME.filterAll')}
          </MenuItem>
          { physiciansList.initialized
            && orderedPhysicians.map((physician, index) => (
              <MenuItem
                key={physician.userId}
                value={physician.userId}
                data-testid={`physician-filter-option-${index + 1}`}
              >
                {composeFullName(physician.firstName, physician.lastName)}
              </MenuItem>
            ))}
        </KnFilterInput>

        {!disabled && (
        <KnFilterInput
          select
          multiple
          value={regimenStatusFilter}
          onChange={onRegimenStatusFilterChange}
          label={translate('HOME.filterByRegimenStatus')}
          data-testid="regimen-status-filter-dropdown"
          hide={disabled}
          renderValue={(selected) => selected.join(', ')}
        >
          {Object.entries(REGIMEN_STATUS_LIST).map(([key, value]) => (
            <MenuItem
              key={key}
              value={value}
              data-testid={`regimen-status-filter-option-${key}`}
            >
              <KnCheckbox checked={regimenStatusFilter.includes(value)} color="primary" />
              <ListItemText primary={value} />
            </MenuItem>
          ))}
        </KnFilterInput>
        )}
      </div>
    </KnBrightBox>
  );
};

KnPatientsTableToolbar.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  onSearchTermChange: PropTypes.func.isRequired,
  onRegimenStatusFilterChange: PropTypes.func.isRequired,
  physicianFilter: PropTypes.number,
  regimenStatusFilter: PropTypes.number.isRequired,
  onPhysicianFilterChange: PropTypes.func.isRequired,
  healthSystemFilter: PropTypes.shape(),
  onHealthSystemFilterChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isAdmin: PropTypes.bool,
};

KnPatientsTableToolbar.defaultProps = {
  physicianFilter: undefined,
  healthSystemFilter: [],
  disabled: false,
  isAdmin: false,
};

export default KnPatientsTableToolbar;
