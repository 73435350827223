/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import differenceInDays from 'date-fns/differenceInDays';
import sub from 'date-fns/sub';
import add from 'date-fns/add';
import startOfDay from 'date-fns/startOfDay';
import { utcToZonedTime } from 'date-fns-tz';
import refDataService, { REFERENCE_DATA_TYPES } from 'services/referenceDataService';
import {
  ADHERENCE_COLORS,
  SYMPTOM_SEVERITY_COLORS,
  SYMPTOM_TYPES,
  TOPLINE_METRICS_BUCKETS_COUNT,
  TOPLINE_METRICS_BUCKET_SIZE,
  TOP_SYMPTOMS_COUNT,
  INSIGHTS_METRICS_DEFAULT_TIME_RANGE,
} from 'Constants';
import {
  subYears, addYears, subMonths, addMonths,
} from 'date-fns';
import { serverDateToGMT } from './utils';

/**
 * Although Amchart fills the inner gaps between 2 non-consecutive entries automatically,
 * if the list has only one OR only consecutive/non-consecutive entries which are less
 * than the given time period, the chart columns display is odd. Let's be defensive
 * and fill in the gaps OR prepend the list with the missing bucket entries before passing
 * the data for rendering.
 * We use the null value as Amchart does to be consistent. If we were to use 0, then a
 * tooltip would appear only for this bucket gaps and not for the ones added by Amcharts.
 *
 * @param {array} list list of metrics buckets
 * @returns {array} The list with the missing buckets added
 */
const fillBucketsGaps = (list) => {
  let copyList = [...list];

  for (let bucketIndex = 1; bucketIndex <= TOPLINE_METRICS_BUCKETS_COUNT; bucketIndex += 1) {
    /** If this bucket has no event (is missing from the `list`),
     * add it with `value` set to `null`
     */
    if (!_.find(list, { bucketIndex })) {
      copyList = [
        ...copyList,
        { bucketIndex, value: null },
      ];
    }
  }
  return copyList;
};

/**
 * Based on TOPLINE_METRICS_BUCKET_SIZE constant,
 * and the given `lastBucketEndDate`,
 * returns the number of the bucket into which the `date` falls
 * @param {Date} date
 * @param {Date} lastBucketEndDate
 * @returns {number} Number of the bucket,
 *  bucket number 1 bucket being the first in time,
 *  and last bucket (ending on lastBucketEndDate) has number TOPLINE_METRICS_BUCKETS_COUNT
 * Note: By previous logic, it is guaranteed, that 'date' doesn't fall
 *  outside of these buckets
 */
const getBucketIndex = (date, lastBucketEndDate) => {
  /** Calculate days past since the event, including the end date */
  const daysPast = differenceInDays(lastBucketEndDate, date) + 1;

  /** Calculate bucket index */
  const quotient = Math.floor(daysPast / TOPLINE_METRICS_BUCKET_SIZE.days);
  const remainder = daysPast % TOPLINE_METRICS_BUCKET_SIZE.days;
  const reversedBucketIndex = quotient + (remainder ? 1 : 0);
  const bucketIndex = (TOPLINE_METRICS_BUCKETS_COUNT - reversedBucketIndex) + 1;

  return bucketIndex;
};

/**
 * Counts all the events which occured in the same time bucket,
 * and returns an aggregated list of objects.
 *
 * @param {array} data List of metrics objects.
 * @param {Date} endDate Last day of the chart (= end date of the last bucket)
 * @returns {array} A list of object in the shape { bucketIndex, value } where
 * bucketIndex is the number of the time bucket (range) and value is the total events
 * that fall into that bucket
 * Note: returned list's size will be TOPLINE_METRICS_BUCKETS_COUNT at most,
 * but might be less, if there are buckets that have no events in them
 */
const aggregateMetricsIntoBuckets = (data, endDate) => {
  const countedEvents = _.countBy(data, (item) => getBucketIndex(item.date, endDate));
  const mappedList = _.map(countedEvents, (count, bucket) => ({
    bucketIndex: Number(bucket),
    value: count,
  }));

  return mappedList;
};

/**
 * Computes the most common symptoms. The second argument specifies
 * how many of the top symptoms should be returned.
 *
 * @param {array} data List of symptom metrics.
 * @param {number} topRange Number of top most common symptoms to return. Defaults to 3.
 * @returns {array} A list of most common symptoms with objects in shape { name, count }.
 */
const getTopCommonSymptoms = (data, topRange) => {
  const symptomsCounts = _.countBy(data, (item) => item.symptomNameValue);
  const aggregatedCounts = _.map(symptomsCounts, (count, name) => ({ count, name }));
  /** When 'count' is the same, chouse by 'name' (alphabetic order) */
  const orderedCounts = _.orderBy(aggregatedCounts, ['count', 'name'], ['desc', 'asc']);
  return _.take(orderedCounts, topRange);
};

/**
 * Counts all the events (seizure or symptom) which occured in the same month
 * and returns an aggregated list of objects.
 *
 * @param {array} data List of seizure/symptom objects.
 * @param {Date} endDate Last day of the chart (= end date of the last bucket)
 */
export const countMetricsByBuckets = (data, endDate) => {
  let countedBuckets = aggregateMetricsIntoBuckets(data, endDate);
  if (countedBuckets.length && (countedBuckets.length < TOPLINE_METRICS_BUCKETS_COUNT)) {
    countedBuckets = fillBucketsGaps(countedBuckets);
  }

  /** List must be sorted by date for the chart visualizations. */
  return _.sortBy(countedBuckets, ['bucketIndex']);
};

/**
 * Groups symptoms by types and counts them by the time buckets they occured.
 *
 * @param {array} data List of symptom objects.
 * @param {Date} endDate Last day of the chart (= end date of the last bucket)
 * @returns {array} List of objects in the shape { typeId, list } where
 * typeId is a type of symptom and list is the symptoms count by time buckets
 */
export const countTopSymptomsByType = (data, endDate, topRange = TOP_SYMPTOMS_COUNT) => {
  const topSymptoms = getTopCommonSymptoms(data, topRange);

  /** Make a list of the top symptom names to be used for filtering the data. */
  const topSymptomNames = _.map(topSymptoms, (symptom) => symptom.name);

  /** Filter the list and make the computations only on the top symptoms. */
  const onlyTopSymptoms = _.filter(
    data,
    (item) => _.includes(topSymptomNames, item.symptomNameValue),
  );

  /** Group symptoms by type */
  const groupSymptomsByType = _.groupBy(onlyTopSymptoms, 'symptomNameValue');

  /** Sort groups based on instance count */
  const sortedGroups = _.orderBy(
    _.map(groupSymptomsByType, (symptoms, type) => ({ type, symptoms })),
    (item) => item.symptoms.length,
    ['desc'],
  );

  /** Finally, aggregate symptoms into buckets */
  const symptomsAggregated = _.map(sortedGroups, (group) => ({
    type: group.type,
    list: countMetricsByBuckets(group.symptoms, endDate),
  }));

  return symptomsAggregated;
};

export const countSeizureMetricsByType = (data) => {
  const groupSeizuresByDate = _.groupBy((data), (item) => startOfDay(item.date));
  const aggregatedSeizures = _.map(groupSeizuresByDate, (events, date) => {
    const countedByType = _.reduce(events, (res, event) => {
      const acc = { ...res };
      let key;
      if (event.eventType !== 'aura') {
        switch (event.convulsions) {
          case true: {
            switch (event.lossOfConsciousness) {
              case true:
                key = 'cna';
                break;
              case false:
                key = 'ca';
                break;
              case null:
                key = 'cu';
                break;
              default:
            }
            break;
          }

          case false: {
            switch (event.lossOfConsciousness) {
              case true:
                key = 'ncna';
                break;
              case false:
                key = 'nca';
                break;
              case null:
                key = 'ncu';
                break;
              default:
            }
            break;
          }

          case null: {
            switch (event.lossOfConsciousness) {
              case true:
                key = 'una';
                break;
              case false:
                key = 'ua';
                break;
              case null:
                key = 'ucua';
                break;
              default:
            }
            break;
          }

          default:
            break;
        }
      } else {
        key = 'aura';
      }

      acc[key] = (acc[key] || 0) + 1;

      if (event.triggers) {
        acc.triggers = {
          ...acc.triggers,
          [key]: acc.triggers && acc.triggers[key]
            ? acc.triggers[key].concat(event.triggers)
            : event.triggers,
        };
      }

      if (event.recoveryTime) {
        acc.recoveryTime = {
          ...acc.recoveryTime,
          [key]: acc.recoveryTime && acc.recoveryTime[key]
            ? [...acc.recoveryTime[key], event.recoveryTime]
            : [event.recoveryTime],
        };
      }

      if (!_.isUndefined(event.didGoToER)) {
        acc.didGoToER = {
          ...acc.didGoToER,
          [key]: acc.didGoToER && acc.didGoToER[key]
            ? [...acc.didGoToER[key], event.didGoToER]
            : [event.didGoToER],
        };
      }

      if (!_.isUndefined(event.didUseRescueMedication)) {
        acc.didUseRescueMedication = {
          ...acc.didUseRescueMedication,
          [key]: acc.didUseRescueMedication && acc.didUseRescueMedication[key]
            ? [...acc.didUseRescueMedication[key], event.didUseRescueMedication]
            : [event.didUseRescueMedication],
        };
      }

      if (!_.isUndefined(event.timePeriod)) {
        acc.timePeriod = {
          ...acc.timePeriod,
          [key]: acc.timePeriod && acc.timePeriod[key]
            ? [...acc.timePeriod[key], event.timePeriod]
            : [event.timePeriod],
        };
      }

      return acc;
    }, {});

    return { date: new Date(date), ...countedByType };
  });

  return _.sortBy(aggregatedSeizures, ['date']);
};

/**
 * Groups and orders symptoms descending by severity and ascending by name.
 *
 * @param {array} symptoms List of symptoms
 */
export const groupSymptomsBySeverity = (symptoms) => {
  const groupedSymptoms = _.groupBy(symptoms, 'severity');
  const aggregatedSymptoms = _.map(groupedSymptoms, (symptomsList, severity) => ({
    severity: Number(severity),
    symptoms: _.sortBy(_.map(symptomsList, (item) => (
      refDataService.getValue(REFERENCE_DATA_TYPES.sideEffect, item.symptomId)
    ))),
  }));

  return _.orderBy(aggregatedSymptoms, ['severity'], ['desc']);
};

export const aggregateLongTermSymptoms = (symptoms) => {
  let seconds = 0;
  const orderedSymptoms = _.orderBy(symptoms, ['date'], ['asc']);

  /**
   * The side-effects are returned without time in the date.
   * In order for the chart to properly count the side-effects
   * when grouped by day, we need to add some time to the entries
   * so they will be identified as separate instances in the same
   * day.
   * Just adding seconds will do the job. This will not affect the
   * accuracy of the side-effect time, as the chance of falling on
   * a different day by adding seconds should be very small (there
   * are 86400 seconds in a day).
   */
  let type;
  const symptomsWithTime = _.map(orderedSymptoms, (s) => {
    seconds += 1;
    if (s.severity === 1) {
      type = SYMPTOM_TYPES.mild;
    } else if (s.severity === 5) {
      type = SYMPTOM_TYPES.severe;
    } else {
      type = SYMPTOM_TYPES.medium;
    }

    return {
      ...s,
      date: add(s.date, { seconds }),
      value: 1,
      y: 0,
      type,
      bullet: 'circle',
    };
  });

  return symptomsWithTime;
};

export const aggregateLongTermMedicationAdherence = (medications, regimenList) => {
  let seconds = 0;
  const orderedMedications = _.orderBy(medications, ['date'], ['asc']);
  const medicationsWithTime = _.map(orderedMedications, (med) => {
    seconds += 1;
    let type = '';
    if (med.taken === false) {
      type = 'missed';
    } else if (med.taken === null) {
      type = 'logged';
    }
    return {
      ...med,
      date: add(med.date, { seconds }),
      value: 1,
      y: 0,
      type,
      bullet: 'circle',
    };
  });

  const regimens = _.get(regimenList, 'data') || [];
  const orderedRegimens = _.orderBy(regimens, ['assignedAt'], ['asc']);
  seconds = 0;
  let regimensWithTime = [];
  _.each(orderedRegimens, (reg) => {
    if (reg.startedAt) {
      seconds += 10;
      regimensWithTime.push({
        ...reg,
        date: add(reg.startedAt, { seconds }),
        value: 1,
        y: 0,
        type: 'started',
        bullet: 'diamond',
      });
    }

    if (reg.status === 'updated') {
      seconds += 10;
      regimensWithTime.push({
        ...reg,
        date: add(serverDateToGMT(reg.titrationUpdateDate), { seconds }),
        value: 1,
        y: 0,
        type: 'updated',
        bullet: 'diamond',
      });
    }
  });

  regimensWithTime = _.orderBy(regimensWithTime, ['date'], ['asc']);
  return [...medicationsWithTime, ...regimensWithTime];
};

/**
 * Creates a time range object, using the offset argument.
 * Accepts an optional timezone argument to use when creating
 * the date range objects.
 *
 * @param {string} timezone Patient's timezone. Optional
 * @returns {object} {startDate, endDate} object
 */
export const getMetricsTimeRange = ({ timezone = undefined, offset }) => {
  /** If timezone is given, we will consider it when creating the time range
   */
  const physicianToday = new Date();
  const endDate = timezone ? utcToZonedTime(physicianToday, timezone) : physicianToday;
  /** Need to substract (x-1) days, to get a range of x days,
   * including start and end date, as we will only use the date parts */
  const startDate = sub(endDate, { days: (offset - 1) });
  return { startDate, endDate };
};

export const getMetricsDefaultTimeRange = (date) => {
  /** On initial display, zoom in the chart on the last 30 days
   * Note: we add an extra 1 day to the end, as chart does the same,
   *  and we want the chart to be visible till the end
   */
  const startDate = sub(date, { days: INSIGHTS_METRICS_DEFAULT_TIME_RANGE.days - 1 });
  const endDate = add(date, { days: 1 });
  return { startDate, endDate };
};

export const getCombinedTimeRange = () => {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  const startDate = subYears(date, 1);
  const endDate = addYears(date, 1);
  return { startDate, endDate };
};

export const getCombinedDefaultTimeRange = () => {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  const startDate = subMonths(date, 2);
  const endDate = addMonths(date, 1);
  return { startDate, endDate };
};

/**
 * Helper function to map the maximum severity of a
 * side-effect data set to a color.
 *
 * @param {array} list List of side-effects data.
 */
export const getColorBySeverity = (list) => {
  /**
   * The tooltip adapter function is called even when hovering
   * the chart, while the request for the data is ongoing.
   * Return a color until the data will be received to prevent
   * a UI crash.
   */
  if (!list.length) return SYMPTOM_SEVERITY_COLORS.mild;

  const maxSeverityItem = _.maxBy(list, 'severity');
  if (maxSeverityItem) {
    switch (maxSeverityItem.severity) {
      case 1:
        return SYMPTOM_SEVERITY_COLORS.mild;
      case 5:
        return SYMPTOM_SEVERITY_COLORS.severe;
      default:
        return SYMPTOM_SEVERITY_COLORS.medium;
    }
  }

  return SYMPTOM_SEVERITY_COLORS.mild;
};


/**
 * Helper function to map the maximum regimen
 * type data set to a color.
 *
 * @param {array} list List of data.
 */
export const getColorByMaxOccurance = (list) => {
  /**
   * The tooltip adapter function is called even when hovering
   * the chart, while the request for the data is ongoing.
   * Return a color until the data will be received to prevent
   * a UI crash.
   */
  if (!list.length) return ADHERENCE_COLORS.missed;
  const groupItems = _.groupBy(list, 'type');
  const items = _.map(_.keys(groupItems), (key) => ({
    count: groupItems[key].length,
    type: key,
  }));
  const maxItem = _.maxBy(items, 'count');
  if (maxItem) {
    return ADHERENCE_COLORS[maxItem.type];
  }

  return ADHERENCE_COLORS.missed;
};
