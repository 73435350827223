/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { fade } from '@material-ui/core/styles/colorManipulator';
import palette from '../colors';

const tableRowsBackgroundColor = palette.white.white2;
const headerCellTextColor = palette.black.black2;
const tableBodyCellTextColor = palette.brownishGrey;
const tableHeaderShadowColor = palette.lightGrey;

export default {
  MuiTableRow: {
    root: {
      backgroundColor: tableRowsBackgroundColor,
    },
    head: {
      boxShadow: `0 1px 2px 0 ${fade(tableHeaderShadowColor, 0.2)}, 0 1px 1px 0 ${fade(tableHeaderShadowColor, 0.15)}, 0 2px 4px 0 ${fade(tableHeaderShadowColor, 0.25)}`,
      /**
       * Box shadow works only on block level elements. We need box-shadow
       * on a display: table-row element; therefore we apply a transform
       * rule in order to create a new stack context and be able to
       * add box shadow. This is the quickest and safest fix, instead of
       * breaking the layout and display of the table by overriding the display
       * property on multiple levels.
       * Note: this does not seem to work on Safari MacOS.
       * SO link source: https://stackoverflow.com/questions/5972525/table-row-box-shadow-on-hover-webkit
       */
      transform: 'scale(1)',
    },
  },
  MuiTableCell: {
    head: {
      fontWeight: 'normal !important',
      color: headerCellTextColor,
      padding: 13,
      '&:first-child': {
        paddingLeft: 16,
      },
    },
    root: {
      fontSize: 16,
      '&:first-child': {
        fontWeight: 500,
      },
      '&:nth-child(2)': {
        fontWeight: 500,
      },
      fontWeight: 400,
      padding: 14,
      lineHeight: 2,
    },
    body: {
      color: tableBodyCellTextColor,
      padding: 8,
      paddingLeft: 13,
      '&:first-child': {
        paddingLeft: 16,
      },
    },
  },
};
