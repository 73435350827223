/**
 * @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React, { useState } from 'react';
import { DatePicker, LocalizationProvider } from '@material-ui/pickers';
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';
import { KnContrastTextField } from './TextField';
import { withKeyNamespace } from '../utils/utils';
import { translate } from '../i18n/i18n';

const i18nKey = withKeyNamespace('DATEPICKER');

const KnDatePicker = ({ ...rest }) => {
  const [error, setError] = useState({});
  const handleError = (type) => {
    setError({ type, message: translate(i18nKey('minDateErrorMessage')) });
  };

  const openPickerButtonStyles = {
    position: 'absolute',
    left: 0,
    borderRadius: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  };

  return (
    <LocalizationProvider dateAdapter={DateFnsAdapter}>
      <DatePicker
        OpenPickerButtonProps={{
          style: openPickerButtonStyles,
        }}
        renderInput={(props) => (
          <KnContrastTextField
            {...props}
            pb={0}
            error={error}
            helperText=""
            required={rest.required}
          />
        )}
        inputFormat="MMM dd, yyyy"
        disableHighlightToday
        onError={handleError}
        {...rest}
      />
    </LocalizationProvider>
  );
};

export default KnDatePicker;
