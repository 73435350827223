/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import { styled } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import palette from 'styles/colors';
import { Trans } from 'react-i18next';
import KnLink from './Link';

const barTextColor = palette.white.white2;
const barBg = palette.greenBlue;

const NotificationWrapper = styled('div')({
  minHeight: 52,
  position: 'sticky',
  top: 64, /** height of the KnHeader */
  zIndex: 10,
  pointerEvents: 'none',
  backgroundColor: 'transparent',

  '& .MuiLink-button': {
    color: 'white',
    pointerEvents: 'auto',
    verticalAlign: 'baseline',
  },
});

/** Component used to display messages at the top of the screen */
const KnNotificationBar = () => {
  const { busyCount, notification } = useSelector((state) => state.app);

  return (
    <NotificationWrapper>
      {(busyCount > 0) && <LinearProgress color="primary" />}
      {notification
      && (
        <Box
          p={2}
          boxShadow={5}
          color={barTextColor}
          bgcolor={barBg}
          data-testid="app-notification-bar"
        >
          <Trans
            i18nKey={notification.messageKey}
            components={[
              <KnLink variant="body2" route={notification.messageRoute} />,
            ]}
          />
        </Box>
      )}
    </NotificationWrapper>
  );
};

export default KnNotificationBar;
