/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';

const KnEyeIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8 2.667c-3.333 0-6.18 2.073-7.333 5 1.153 2.927 4 5 7.333 5s6.18-2.073 7.333-5c-1.153-2.927-4-5-7.333-5zM8 11a3.335 3.335 0 0 1 0-6.666A3.335 3.335 0 0 1 8 11zM6 7.667c0-1.107.893-2 2-2s2 .893 2 2-.893 2-2 2-2-.893-2-2z" fill="#666" />
  </svg>
);

export default KnEyeIcon;
