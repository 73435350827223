/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import _ from 'lodash';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import palette from 'styles/colors';
import { v4 as uuidv4 } from 'uuid';
import {
  calculateTabletCount,
  tabletCountDisplay,
  capFirstCharOnly,
} from 'utils/utils';

const { darkGrey, plainGrey } = palette;

const KnTd1 = styled('td')({ width: '100px', color: darkGrey });
const KnTd2 = styled('td')({ width: '100px', color: plainGrey });
const KnTd3 = styled('td')({ width: '45px', color: plainGrey });
const KnTd4 = styled('td')({ width: '100px', marginLeft: '45px', color: plainGrey });
const KnTd5 = styled('td')({ width: '100px', marginLeft: '100px', color: plainGrey });
const KnSpan = styled('span')({ marginLeft: '5px' });
const KnSpan1 = styled('span')({ marginLeft: '3px' });
const KnDiv = styled('div')({ alignItems: 'center', display: 'flex', flexDirection: 'column' });

const KnTypography = styled(Typography)({ fontSize: '16px', color: plainGrey });
const KnTypography01 = styled(Typography)({
  marginTop: '-25px', marginLeft: '62px', fontSize: '16px', fontWeight: '400', color: darkGrey,
});
const KnTypography1 = styled(Typography)({ fontSize: '16px', fontWeight: '401', color: darkGrey });
const KnTypography2 = styled(Typography)({
  position: 'relative',
  top: '-21px',
  marginBottom: '-23%',
  right: '-48px',
  color: plainGrey,
});
const KnTypography3 = styled(Typography)(({ medicationStrength }) => ({
  position: 'relative',
  marginBottom: '-23%',
  top: (medicationStrength) ? '-70px' : '-45px',
  right: '-48px',
  color: plainGrey,
}));
const KnTypography4 = styled(Typography)(({ frequency }) => {
  if (frequency > 0) return ({ marginLeft: '10px', marginTop: '-23px', color: plainGrey });
  return ({ marginTop: '1px', color: plainGrey });
});

const KnCollaspedTable = ({ medication }) => {
  const { t: translate } = useTranslation();
  const {
    offset, medicationStrength, medicationType, medicationTypeUnit,
  } = medication;

  const offsetFunction = () => (
    <KnTypography>
      {translate('FIELD_LABELS.offset')}
      :
      <KnSpan>{offset || ' - '}</KnSpan>
      <KnSpan1>{(offset > 1) ? translate('FIELD_LABELS.days') : (offset) && translate('FIELD_LABELS.day')}</KnSpan1>
    </KnTypography>
  );


  return (
    <>
      <table>
        <tbody>
          {!!medicationStrength && (
            <KnTd1>
              <KnTypography01 pt={2}>
                {`${capFirstCharOnly(medicationType)}${translate('FIELD_LABELS.suspensionStrength')}${medicationStrength}${medicationTypeUnit}`}
              </KnTypography01>
            </KnTd1>
          )}
          {medication.dosages.map((dose, doseIndex) => (
            <tr key={uuidv4()}>
              <Box display="flex" key={dose.index} flexDirection="row" pt={0} pl={8} pr={6}>
                <KnTd1>
                  <KnTypography1 pt={2}>
                    {translate('FIELD_LABELS.dosage')}
                    <KnSpan>{doseIndex + 1}</KnSpan>
                  </KnTypography1>
                </KnTd1>
                <KnTd2>
                  <KnTypography pt={2}>
                    {dose.quantity}
                    {medication.unit}
                  </KnTypography>
                </KnTd2>
                <KnTd3>
                  <KnTypography>
                    {dose.frequency}
                    <KnTypography4 frequency={dose.frequency}>
                      {dose.frequency > 1 ? translate('FIELD_LABELS.times') : translate('FIELD_LABELS.time')}
                      :
                    </KnTypography4>

                  </KnTypography>
                </KnTd3>
                {!dose.hasCustomDosage ? _.times(4, (doseTimeIndex) => (
                  <KnTd2 key={uuidv4()}>
                    <KnTypography>

                      <KnDiv>
                        {
                          dose.times[doseTimeIndex]
                            ? dose.times[doseTimeIndex].timePeriod.value
                            : null
                        }
                        <KnTypography2>
                          {' '}
                          {(doseTimeIndex < dose.times.length - 1) && '|'}
                          {' '}
                        </KnTypography2>
                      </KnDiv>
                    </KnTypography>
                  </KnTd2>
                )) : _.times(4, (doseTimeIndex) => (
                  <KnTd2 key={uuidv4()}>
                    <KnTypography>
                      <KnDiv>
                        {dose.times[doseTimeIndex]
                          && (
                            <>
                              {dose.times[doseTimeIndex].timePeriod.value}
                              <span>
                                {dose.individualQuantities[doseTimeIndex]}
                                {dose.individualQuantities[doseTimeIndex] ? medication.unit : null}
                              </span>
                              {!!medicationStrength && (
                                <span>
                                  {tabletCountDisplay(calculateTabletCount(
                                    dose.individualQuantities[doseTimeIndex],
                                    medicationStrength,
                                  ), medicationType)}
                                </span>
                              )}
                            </>
                          )}

                        {(doseTimeIndex < dose.times.length - 1)
                          && (
                          <KnTypography3 medicationStrength={medicationStrength}>
                            |
                          </KnTypography3>
                          )}
                      </KnDiv>

                    </KnTypography>
                  </KnTd2>
                ))}
                <KnTd4>
                  <KnTypography>
                    {translate('FIELD_LABELS.duration')}
                    :
                    <KnSpan>{dose.duration}</KnSpan>
                    <KnSpan1>{dose.duration > 1 ? translate('FIELD_LABELS.days') : translate('FIELD_LABELS.day')}</KnSpan1>
                  </KnTypography>
                </KnTd4>

                {doseIndex === 0 && (
                  <KnTd5>
                    {offsetFunction()}
                  </KnTd5>
                )}
              </Box>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

KnCollaspedTable.defaultProps = {
  medication: {},
};

KnCollaspedTable.propTypes = {
  medication: PropTypes.shape(),
};

export default KnCollaspedTable;
