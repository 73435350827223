/**
 * @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React from 'react';
import { DesktopDateRangePicker, LocalizationProvider } from '@material-ui/pickers';
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';
import { KnContrastTextField } from './TextField';

const KnDateRangePicker = ({ ...rest }) => (
  <LocalizationProvider dateAdapter={DateFnsAdapter}>
    <DesktopDateRangePicker
      startText="Date range"
      inputFormat="MMM dd, yyyy"
      disableHighlightToday
      renderInput={(startProps, endProps) => (
        <KnContrastTextField
          {...startProps}
          variant="filled"
          helperText=""
          pb={0}
          inputProps={{ ...startProps.inputProps, value: `${startProps.inputProps.value} - ${endProps.inputProps.value}`, readOnly: true }}
          style={{ width: 250, cursor: 'pointer' }}
        />
      )}
      {...rest}
    />
  </LocalizationProvider>
);

export default KnDateRangePicker;
