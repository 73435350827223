/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import React, {
  useCallback, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, DialogContentText, styled } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import KnButton from 'components/Button';
import { withKeyNamespace } from 'utils/utils';
import KnValidatedTextField from 'components/ValidatedTextField';
import { KnContrastTextField } from 'components/TextField';
import { NAME_FORMAT, MATCH_DATE_STRING } from 'Constants';
import { format } from 'date-fns';

const i18nKey = withKeyNamespace('PATIENT_RECORD.editInvitedPatientDialog');

const KnButtonsWrappers = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',

  '& > *:first-child': {
    marginBottom: theme.spacing(1),
  },
}));

const KnInputWrappers = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',

  '& > *:first-child': {
    width: theme.spacing(16),
    marginRight: theme.spacing(2),
    paddingBottom: 0,
  },

  '& > *:nth-child(2)': {
    paddingTop: theme.spacing(2.5),
  },
}));

const KnFormHelperTexts = styled(FormHelperText)({
  top: '-40px',
  right: '20px',
  position: 'relative',
});

const KnValidatedTextFieldChnage = styled(KnValidatedTextField)(() => ({
  marginBottom: '30px',
  top: '10px',
  left: '-14px',
  width: '400px',
  position: 'relative',
}));

const KnDialogInvitePatientEditInfo = ({
  open,
  onSubmit,
  onClose,
  inputData,
}) => {
  const {
    firstName,
    lastName,
    dob,
  } = inputData;
  const { t: translate } = useTranslation();
  const {
    handleSubmit, errors, control, getValues, triggerValidation, watch,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const [saveDisable, setSaveDisable] = useState(true);


  useEffect(() => {
    if (getValues('invitedPatientLastName') || getValues('invitedPatientfirstName') || getValues('invitedPatientDOB')) {
      setSaveDisable(false);
    } else setSaveDisable(true);
  }, [getValues, triggerValidation, watch, errors, control]);

  const submitStrength = useCallback(() => {
    const data = {};
    if (getValues('invitedPatientLastName')) data.lastName = getValues('invitedPatientLastName');
    if (getValues('invitedPatientfirstName')) data.firstName = getValues('invitedPatientfirstName');
    if (getValues('invitedPatientDOB')) data.dob = getValues('invitedPatientDOB');
    onSubmit({ ...data });
  }, [getValues, onSubmit]);

  if (open) {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        data-testid="dialog-wrapper"
      >
        <DialogTitle data-testid="dialog-title" onClose={onClose}>
          <Box display="flex" justifyContent="space-between">
            {translate(i18nKey('title'))}
          </Box>
        </DialogTitle>
        <DialogContent data-testid="dialog-content">

          {/** We don't call handleSubmit(onSubmit) directly,
           * as we want to convert medicationStrength from string to Number first,
           * which is done by `submitStrength`
           */}
          <form onSubmit={handleSubmit(submitStrength)}>
            <KnInputWrappers style={{ flexDirection: 'column', marginLeft: '20px' }}>
              <KnValidatedTextFieldChnage
                Component={KnContrastTextField}
                disableLabel
                disableErrorMessage
                name="invitedPatientLastName"
                control={control}
                errors={errors}
                trimSpaces
                format={NAME_FORMAT}
                defaultValue={lastName || ''}
                label={translate(i18nKey('lastName'))}
                maxLength={30}
              />
              <KnValidatedTextFieldChnage
                Component={KnContrastTextField}
                disableLabel
                disableErrorMessage
                name="invitedPatientfirstName"
                control={control}
                errors={errors}
                trimSpaces
                format={NAME_FORMAT}
                defaultValue={firstName || ''}
                label={translate(i18nKey('firstName'))}
                maxLength={30}
              />
              <KnValidatedTextFieldChnage
                Component={KnContrastTextField}
                disableLabel
                disableErrorMessage
                name="invitedPatientDOB"
                control={control}
                errors={errors}
                trimSpaces
                format={MATCH_DATE_STRING}
                defaultValue={(dob !== 'N/A') ? format(new Date(dob), 'MM/dd/yyyy') : ''}
                label={translate(i18nKey('dob'))}
              />

              <KnFormHelperTexts style={{ position: 'relative', top: '-40px', right: '20px' }} error data-testid="strength-validation-error">
                {_.get(errors, 'invitedPatientDOB.message', '')}
              </KnFormHelperTexts>

              <DialogContentText style={{ marginTop: '-35px', marginLeft: '-13px' }} data-testid="dialog-content-text">
                {translate(i18nKey('message'))}
              </DialogContentText>
            </KnInputWrappers>
          </form>


        </DialogContent>
        <DialogActions data-testid="dialog-actions">
          <KnButtonsWrappers style={{ flexDirection: 'row', justifyContent: 'space-between' }}>

            <KnButton
              variant="text"
              onClick={onClose}
              data-testid="dialog-reset-button"
            >
              {translate(i18nKey('cancel'))}
            </KnButton>
            <KnButton
              onClick={submitStrength}
              disabled={saveDisable}
              data-testid="dialog-submit-button"
            >
              {translate(i18nKey('save'))}
            </KnButton>
          </KnButtonsWrappers>
        </DialogActions>
      </Dialog>
    );
  }

  return null;
};

KnDialogInvitePatientEditInfo.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  inputData: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    dob: PropTypes.string,
  }),
};

KnDialogInvitePatientEditInfo.defaultProps = {
  inputData: {
    firstName: undefined,
    lastName: undefined,
    dob: 'N/A',
  },
};

export default KnDialogInvitePatientEditInfo;
