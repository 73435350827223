/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useDialog from 'components/dialog/DialogService';
import PropTypes from 'prop-types';
import patientActions from 'redux/actions/patientActions';
import Typography from '@material-ui/core/Typography';
import { KnSectionHeader, KnSmallLabel } from 'components/Typography';
import KnErrorMessage from 'components/ErrorMessage';
import { undisclosed, withKeyNamespace } from 'utils/utils';
import {
  APP_PAGE_URLS, PATIENTS_LIST_TABS, REGIMEN_STATUS,
  PATIENT_LINKS_STATUS,
  API_REQUEST_ERROR_CODES,
} from 'Constants';
import { KnActionLink } from 'components/Link';
import KnUnlinkIcon from 'components/icons/UnlinkIcon';
import KnEditPatientInfoIcon from 'components/icons/EditPatientInfoIcon';
import useSessionStorage, { SESSION_STORAGE_KEYS } from 'utils/sessionStorage';
import { styled } from '@material-ui/core';
import KnDialogInvitePatientEditInfo from './DialogInvitePatientEditInfo';
import KnPatientRecordBox, {
  KnPatientNameBox,
  KnPatientInfoField,
  KnPatientInfoBox,
  KnPatientInfoWideBox,
} from './styles';

const i18nKey = withKeyNamespace('PATIENT_RECORD');
const KnActionLinkStyled = styled(KnActionLink)(({ len }) => ({
  marginTop: '-22px', marginLeft: `${80 + (len * 8)}px`,
}));

/**
 * Component for displaying basic patient information
 * @param {string} patientId Id of the patient to be displayed
 */
const KnPatientInfomation = ({ patientId, patientType, hideEdit }) => {
  const unmounted = useRef(false);
  const {
    data: patientInfo,
    error: patientInfoError,
  } = useSelector((state) => state.patientRecord.patientInfo);
  const { data: patientRegimens, error: patientRegimensError } = useSelector(
    (state) => state.patientRecord.regimens,
  );
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const dialog = useDialog();
  const history = useHistory();
  const [, setPatientsListTabSelection] = useSessionStorage(SESSION_STORAGE_KEYS.patientsListTab);

  /**
   * Some of the data we already have in the state field,
   * but still need to fetch the rest
   */
  useEffect(() => {
    if (patientType) dispatch(patientActions.fetchInvitedPatientInfo(patientId));
    else dispatch(patientActions.fetchPatientInfo(patientId));
  }, [dispatch, patientId, patientType]);

  useEffect(() => () => { unmounted.current = true; }, []);

  const onEditInfoClick = useCallback(() => {
    dialog({
      customDialog: KnDialogInvitePatientEditInfo,
      inputData: { ...patientInfo },
    }).then((e) => {
      dispatch(patientActions.editInvitedPatientDialog(patientId, e)).then(() => {
        dispatch(patientActions.fetchInvitedPatientInfo(patientId));
      });
    });
  }, [dialog, patientInfo, dispatch, patientId]);

  const unlinkPatient = useCallback(() => {
    const activeRegimens = patientRegimens.filter(
      (regimen) => (regimen.status === REGIMEN_STATUS.active),
    );

    if (activeRegimens.length) {
      dialog({
        description: translate(i18nKey('unlinkPatient.unlinkWarningDialog.content')),
        submitLabel: translate('GENERAL.okButton'),
      });
    } else {
      dialog({
        title: translate(i18nKey('unlinkPatient.confirmUnlinkDialog.title')),
        description: translate(i18nKey('unlinkPatient.confirmUnlinkDialog.content')),
        submitLabel: translate('GENERAL.continueButton'),
        closeLabel: translate('GENERAL.cancelButton'),
      }).then(() => {
        dispatch(patientActions.unlinkPatient(patientInfo.linkId)).then(() => {
          /** (Re)fetch unlinked patients list (unfiltered, not silent) */
          dispatch(patientActions.fetchPatients(PATIENT_LINKS_STATUS.unlinked, false));
          if (!unmounted.current) {
            /** and navigate to it, if user is still on patient record */
            setPatientsListTabSelection(PATIENTS_LIST_TABS.UNLINKED_PATIENTS);
            history.push(APP_PAGE_URLS.patientList.replace(':listType', 'verified'));
          }
        },
        () => { });
      });
    }
  }, [dialog, dispatch, history, patientInfo, patientRegimens,
    translate, setPatientsListTabSelection,
  ]);

  const redoFetchPatientInfo = useCallback(() => {
    if (patientType) dispatch(patientActions.fetchInvitedPatientInfo(patientId));
    else dispatch(patientActions.fetchPatientInfo(patientId));
  }, [dispatch, patientId, patientType]);

  let patientName;
  if (patientInfo) {
    patientName = patientInfo.firstName;
    if (patientInfo.lastName) {
      patientName = patientName ? `${patientName} ${patientInfo.lastName}` : patientInfo.lastName;
    }
  }

  const errorMessageKey = useMemo(() => {
    const patientNotFound = patientInfoError === API_REQUEST_ERROR_CODES.USER_NOT_FOUND;
    return i18nKey(`ERROR_MESSAGES.${patientNotFound ? 'patientNotFoundError' : 'fetchError'}`);
  }, [patientInfoError]);

  return (
    <KnPatientRecordBox display="flex" mb={2}>
      {patientInfo
        && (
          <>
            <KnPatientNameBox>
              <Typography variant="h6" component={KnSectionHeader}>
                {undisclosed(patientName)}
              </Typography>
              {(patientType && hideEdit) && (
                <KnActionLinkStyled
                  len={undisclosed(patientName).length}
                  LhsIcon={KnEditPatientInfoIcon}
                  onClick={() => { onEditInfoClick(); }}
                />
              )}
              <Typography component={KnSmallLabel}>
                {translate('GENERAL.lastActive', {
                  date: undisclosed(
                    patientInfo.lastActive && translate('GENERAL.date', { date: patientInfo.lastActive }),
                  ),
                })}
              </Typography>
            </KnPatientNameBox>
            <KnPatientInfoBox>
              <Typography component={KnSmallLabel}>
                {translate('GENERAL.dob')}
              </Typography>
              <Typography component={KnPatientInfoField}>
                {undisclosed(translate('GENERAL.date', { date: patientInfo.dob }))}
              </Typography>
            </KnPatientInfoBox>
            <KnPatientInfoBox>
              <Typography component={KnSmallLabel}>
                {translate('GENERAL.gender.label')}
              </Typography>
              <Typography component={KnPatientInfoField}>
                {undisclosed(patientInfo.gender && translate(`GENERAL.gender.${patientInfo.gender}`))}
              </Typography>
            </KnPatientInfoBox>
            <KnPatientInfoBox>
              <Typography component={KnSmallLabel}>
                {translate(i18nKey('diagnosedSince'))}
              </Typography>
              <Typography component={KnPatientInfoField}>
                {undisclosed(patientInfo.diagnosedSince)}
              </Typography>
            </KnPatientInfoBox>
            <KnPatientInfoWideBox>
              <Typography component={KnSmallLabel}>
                {translate('GENERAL.email')}
              </Typography>
              <Typography component={KnPatientInfoField}>
                {undisclosed(patientInfo.email)}
              </Typography>
            </KnPatientInfoWideBox>
            <KnPatientInfoBox>
              <Typography component={KnSmallLabel}>
                {translate('GENERAL.phone')}
              </Typography>
              <Typography component={KnPatientInfoField}>
                {undisclosed(patientInfo.phoneNumber)}
              </Typography>
            </KnPatientInfoBox>

            {/* We need medication regimens to know if the patient can be unlinked. */}
            <KnActionLink
              LhsIcon={KnUnlinkIcon}
              disabled={!patientRegimens || patientRegimensError}
              data-testid="unlink-patient-button"
              onClick={unlinkPatient}
            >
              {translate(i18nKey('unlinkPatient.button'))}
            </KnActionLink>
          </>
        )}
      <KnErrorMessage
        error={!!patientInfoError}
        messageKey={errorMessageKey}
        onRetry={redoFetchPatientInfo}
        centered={false}
        mb={2}
        data-testid="patient-information-fetch-error"
      />
    </KnPatientRecordBox>
  );
};

KnPatientInfomation.propTypes = {
  patientId: PropTypes.string.isRequired,
  patientType: PropTypes.bool,
  hideEdit: PropTypes.bool,
};

KnPatientInfomation.defaultProps = {
  patientType: false,
  hideEdit: true,
};

export default KnPatientInfomation;
