/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { styled } from '@material-ui/core/styles';
import { KnBoldSectionHeader } from 'components/Typography';
import KnButton from 'components/Button';

const KnBoldSectionHeaderSignUp = styled(KnBoldSectionHeader)({
  marginBottom: 24,
});

const LoaderBox = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
});

const EULAWrapper = styled('div')({
  position: 'relative',
  height: '75vh',
});

const EULAStep = ({ onSuccess, eulaHandleBack }) => {
  const { t: translate } = useTranslation();
  const [loader, setLoader] = React.useState(true);

  // temporary enable agree button until we will fix for the windows
  // const [isBottom, setIsBottom] = React.useState(false);
  // React.useEffect(() => {
  //   const iframe = document.getElementById('EULAIframe');
  //   setTimeout(() => {
  // eslint-disable-next-line max-len
  //     const obj = iframe.contentWindow && iframe.contentWindow.document.getElementsByClassName('WordSection1')[0];
  //     if (obj) {
  //       obj.addEventListener('scroll', () => {
  //         if (obj.scrollTop === (obj.scrollHeight - obj.offsetHeight)) {
  //           setIsBottom(true);
  //         } else {
  //           setIsBottom(false);
  //         }
  //       });
  //     }
  //   }, 2000);
  // }, []);

  const onSubmit = () => {
    onSuccess({});
  };

  const hideSpinner = () => {
    setLoader(false);
  };

  return (
    <EULAWrapper>
      <Typography variant="h6" component={KnBoldSectionHeaderSignUp}>
        {translate('REGISTER.eulaTitle')}
      </Typography>
      {loader && <LoaderBox><CircularProgress /></LoaderBox>}
      <iframe
        title="Nile EULA"
        id="EULAIframe"
        src={`${window.location.origin}/EULA.html`}
        width="100%"
        height="85%"
        onLoad={hideSpinner}
        style={{ border: 'none' }}
      />
      {!loader && (
        <Box
          pt={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <KnButton
            variant="text"
            type="button"
            onClick={eulaHandleBack}
            data-testid="eula-login-link-button"
          >
            {translate('REGISTER.backToLogin')}
          </KnButton>
          <KnButton
            data-testid="eula-agree-button"
            onClick={onSubmit}
          >
            {translate('REGISTER.agree')}
          </KnButton>
        </Box>
      )}
    </EULAWrapper>
  );
};

EULAStep.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  eulaHandleBack: PropTypes.func.isRequired,
};

export default EULAStep;
