/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, { useCallback, useEffect, useMemo } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
  useHistory,
  useLocation,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import useCognitoUser from 'utils/cognitoUser';
import { getMobileOperatingSystem, useIsUserPending } from 'utils/utils';
import initI18n from 'i18n/i18n';
import { ThemeProvider } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from 'styles/theme';
import appActions from 'redux/actions/appActions';
import loginActions from 'redux/actions/loginActions';
import { APP_PAGE_URLS, NILE_APP_LINKS } from 'Constants';
import KnLayout from 'components/Layout';
import LoginPage from 'features/login/LoginPage';
import RegisterPage from 'features/register/RegisterPage';
import HomePage from 'features/home/HomePage';
import PatientRecordPage from 'features/patient/PatientRecordPage';
import ForgotPasswordPage from 'features/forgotPassword/ForgotPasswordPage';
import SettingsPage from 'features/account/SettingsPage';
import FAQPage from 'features/faq/FAQPage';
import AppRedirect from 'features/login/AppRedirect';
import TitrationBuilderPage from 'features/titrations/TitrationBuilderPage';
import TitrationsManagementPage from 'features/titrations/TitrationsManagementPage';
import AssignPresetPage from 'features/patient/AssignPresetPage';
import AssignRegimenPage from 'features/patient/AssignRegimenPage';
import ThresholdSetupPage from 'features/patient/ThresholdSetupPage';
import { KnDialogServiceProvider } from 'components/dialog/DialogService';
import Amplify, { Auth } from 'aws-amplify';
import { addLicense } from '@amcharts/amcharts4/core';
import AssignSurveyPage from './features/patient/AssignSurveyPage';

initI18n();

/** Configure Amplify with the CognitoPool settings  */
const cognitoConfig = {
  region: process.env.REACT_APP_COGNITO_REGION,
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
};
Amplify.configure({ Auth: cognitoConfig });
Auth.configure({ storage: localStorage });

/**
 * Our own HOC component around react Route,
 * used to protect routes
 * Set `shouldBeAuthenticated` to `false`,
 *  to those routes that are accessible when user is not authenticated
 * Set `shouldBeAuthenticated` to `true` (default value),
 *  to those routes that are accessible when user is authenticated
 */
const KnRoute = (props) => {
  const {
    component: Component,
    shouldBeAuthenticated,
    ...rest
  } = props;
  const cognitoUser = useCognitoUser();
  const isUserPending = useIsUserPending();
  const location = useLocation();

  const { shouldRedirect, redirectPath } = useMemo(() => {
    const authenticated = (cognitoUser !== null);
    const homeURL = APP_PAGE_URLS.patientList.replace(':listType', 'verified');
    /* eslint-disable no-shadow */
    const shouldRedirect = (shouldBeAuthenticated !== authenticated)
      || (isUserPending && location.pathname !== homeURL);
    const redirectPath = authenticated ? homeURL : APP_PAGE_URLS.login;
    /* eslint-enable no-shadow */
    return { shouldRedirect, redirectPath };
  }, [cognitoUser, shouldBeAuthenticated, isUserPending, location.pathname]);

  /**
   * cognitoUser is undefined while we are waiting for
   * the AWS user data. Until then, we'll show no content.
   * OR
   * user is signed in, but isUserPending is undefined,
   * meaning that we're still waiting for GET /users response,
   * to decide wether user is pending or not
   */
  if (cognitoUser === undefined || (cognitoUser && isUserPending === undefined)) {
    return null;
  }

  /** redirect when needed,
   * if not, just render requested component
   */
  return (
    <Route
      {...rest}
      render={({ ...routerProps }) => (
        shouldRedirect
          ? (
            <Redirect to={{
              pathname: redirectPath,
              state: { redirectPath: location.pathname !== '/' ? location.pathname : '' },
            }}
            />
          )
          : <Component {...routerProps} />
      )}
    />
  );
};

KnRoute.propTypes = {
  component: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  shouldBeAuthenticated: PropTypes.bool,
};

KnRoute.defaultProps = {
  shouldBeAuthenticated: true,
};

/** Wrapper for KnRoute that sets `shouldBeAuthenticated` to false, for convinience */
const KnAnonymousRoute = (props) => <KnRoute {...props} shouldBeAuthenticated={false} />;

const Main = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const cognitoUser = useCognitoUser();

  useEffect(() => {
    addLicense('CH236683054');
  }, []);

  const onIdle = useCallback(() => {
    /** On inactivity, if the user is logged in, trigger a logout. */
    if (cognitoUser) {
      dispatch(loginActions.logout());
    }
  }, [dispatch, cognitoUser]);

  const redirectToAppPage = useCallback(() => {
    const device = getMobileOperatingSystem();
    window.location.href = NILE_APP_LINKS[device] || NILE_APP_LINKS.web;
    return null;
  }, []);

  useIdleTimer({
    timeout: Number(process.env.REACT_APP_USER_INACTIVITY_TIMEOUT),
    onIdle,
    debounce: 500,
  });

  useEffect(() => {
    /**
     * Fetch BE API version number
     */
    dispatch(appActions.fetchApiVersion());
  }, [dispatch]);

  useEffect(() => {
    /**
     * Listen to React Router's history change event and
     * dispatch an action to remove any application error
     * notification, because it becomes obsolete when
     * leaving the page.
     */
    const unsubscribe = history.listen(() => {
      dispatch(appActions.appPopNotification());
    });

    return unsubscribe;
  }, [dispatch, history]);

  return (
    <Box display="flex" flex="1">
      <Switch>
        <KnAnonymousRoute exact path={APP_PAGE_URLS.login} component={LoginPage} />
        <KnAnonymousRoute path={APP_PAGE_URLS.register} component={RegisterPage} />
        <KnAnonymousRoute path={APP_PAGE_URLS.appRedirect} component={AppRedirect} />
        <KnAnonymousRoute path={APP_PAGE_URLS.forgotPassword} component={ForgotPasswordPage} />
        <KnAnonymousRoute
          path={[
            APP_PAGE_URLS.emailAppLink,
            APP_PAGE_URLS.registerAppLink,
            APP_PAGE_URLS.careGiverInvite,
          ]}
          component={redirectToAppPage}
        />

        <KnRoute path={APP_PAGE_URLS.assignSurvey} component={AssignSurveyPage} />

        <KnRoute path={APP_PAGE_URLS.patientList} component={HomePage} />
        <KnRoute path={APP_PAGE_URLS.patientRecord} component={PatientRecordPage} />
        <KnRoute path={APP_PAGE_URLS.invitedPatientRecord} component={PatientRecordPage} />
        <KnRoute path={APP_PAGE_URLS.settings} component={SettingsPage} />
        <KnRoute path={APP_PAGE_URLS.faq} component={FAQPage} />
        <KnRoute path={APP_PAGE_URLS.newTitration} component={TitrationBuilderPage} />
        <KnRoute path={APP_PAGE_URLS.editTitration} component={TitrationBuilderPage} />
        <KnRoute
          path={APP_PAGE_URLS.titrationsList}
          component={TitrationsManagementPage}
        />
        <KnRoute path={APP_PAGE_URLS.assignPreset} component={AssignPresetPage} />
        <KnRoute path={APP_PAGE_URLS.assignRegimen} component={AssignRegimenPage} />
        <KnRoute path={APP_PAGE_URLS.editAssignedRegimen} component={AssignRegimenPage} />
        <KnRoute path={APP_PAGE_URLS.threshold} component={ThresholdSetupPage} />

        <KnRoute path={APP_PAGE_URLS.assignSurvey} component={AssignSurveyPage} />

        <Redirect to={APP_PAGE_URLS.login} />
      </Switch>
    </Box>
  );
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <KnLayout>
          <KnDialogServiceProvider>
            <Main />
          </KnDialogServiceProvider>
        </KnLayout>
      </Router>
    </ThemeProvider>
  );
}

export default App;
