import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import KnCheckIcon from 'components/icons/CheckIcon';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from 'react-i18next';
import { withKeyNamespace } from 'utils/utils';
import { format } from 'date-fns';
import palette from 'styles/colors';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core';
import { KnMetricsTitle } from './styles';


const i18nKey = withKeyNamespace('PATIENT_RECORD');
const {
  primary, darkGrey, black, green,
} = palette;

const KnTypography = styled(Typography)({
  fontSize: '12px',
});

const KnTypography1 = styled(KnTypography)({
  marginLeft: '5px',
});

const KnDivider = styled(Divider)({
  marginBottom: '8px',
});

const KnTypographyChange = styled(Typography)(({ fs, ml, mt }) => ({
  fontSize: fs, marginLeft: ml, marginTop: mt,
}));

const KnBox = styled(Box)({
  width: '9px',
  height: '2px',
  margin: '7px 10px 25px 0',
});


const KnSurveyList = ({ surveys, surveyResults, onSetSurveyDetails }) => {
  const { t: translate } = useTranslation();
  return (
    <Box display="flex" flexDirection="column" mt="23px">
      {surveys.map((survey) => surveyResults
            && (
              <Box>
                <ExpansionPanel square elevation={0}>
                  <ExpansionPanelSummary>
                    <Box display="flex" flexDirection="column">
                      <Box display="flex" justifyContent="space-between">
                        <Box>
                          <Typography display="block" component={KnMetricsTitle}>{survey.title}</Typography>
                        </Box>
                        {survey.details && (
                        <Box style={{ color: primary, fontSize: '11px' }} onClick={() => onSetSurveyDetails(survey)}>
                          {translate(i18nKey('surveyInsights.details'))}
                        </Box>
                        )}
                      </Box>
                      {!survey.recurring
                        ? (
                          <Box display="flex" mb="8px" flexDirection="column">
                            {survey.taken ? (
                              <Box display="flex">
                                <Box margin="-3px 4px 8px 0">
                                  <KnCheckIcon fill={green.dark} width="16px" />
                                </Box>
                                <Box display="flex">
                                  <KnTypography color={darkGrey}>{translate(i18nKey('surveyInsights.completed'))}</KnTypography>
                                  <KnTypography1>{format(survey.completedDate, 'MM/dd')}</KnTypography1>
                                  <KnTypography1>{new Date(survey.completedDate).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' })}</KnTypography1>
                                </Box>
                              </Box>
                            ) : (
                              <Box display="flex">
                                <KnBox style={{ background: black.black1 }} />
                                <KnTypography color={darkGrey}>{translate(i18nKey('surveyInsights.surveyNotCompleted'))}</KnTypography>
                              </Box>
                            )}
                            <Box display="flex" justifyContent="space-between">
                              <Box>
                                <KnTypographyChange fs="12px" ml="19px" mt="-18px">
                                  {`${translate(i18nKey('surveyInsights.score'))}: ${survey.score}`}
                                </KnTypographyChange>
                              </Box>
                              {survey.taken && (
                              <Box>
                                <KnTypographyChange fs="10px" ml="19px" mt="-19px"> </KnTypographyChange>
                              </Box>
                              )}
                            </Box>
                          </Box>
                        ) : (
                          <Box display="flex" flexDirection="column">
                            <Box display="flex" mt="8px" mb="6px" ml="18px">
                              <KnTypography color={darkGrey}>{translate(i18nKey('surveyInsights.upcomingSurvey'))}</KnTypography>
                              <KnTypography1>{format(survey.upcomingSurvey, 'MM/dd')}</KnTypography1>
                            </Box>
                            {!!survey.takenSurveyLength && (
                            <Box>
                              <KnDivider />
                              <Box display="flex">
                                {survey.taken ? (
                                  <>
                                    <Box margin="-3px 4px 8px 0">
                                      <KnCheckIcon fill={green.dark} width="16px" />
                                    </Box>
                                    <Box display="flex">
                                      <KnTypography color={darkGrey}>{translate(i18nKey('surveyInsights.completed'))}</KnTypography>
                                      <KnTypography1>{format(survey.completedDate, 'MM/dd')}</KnTypography1>
                                      <KnTypography1>{new Date(survey.completedDate).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' })}</KnTypography1>
                                    </Box>
                                  </>
                                )
                                  : (
                                    <>
                                      <KnBox style={{ background: black.black1 }} />
                                      <KnTypography color={darkGrey}>{translate(i18nKey('surveyInsights.surveyNotCompleted'))}</KnTypography>
                                    </>
                                  )}
                              </Box>
                              <Box display="flex" justifyContent="space-between">
                                <Box>
                                  <KnTypographyChange fs="12px" ml="19px" mt="-18px">
                                    {`${translate(i18nKey('surveyInsights.score'))}: ${survey.score}`}
                                  </KnTypographyChange>
                                </Box>
                              </Box>
                            </Box>
                            )}
                          </Box>
                        )}
                    </Box>
                  </ExpansionPanelSummary>
                  {surveys.length < 0 && (<ExpansionPanelDetails />)}
                </ExpansionPanel>
              </Box>
            ))}
    </Box>
  );
};


KnSurveyList.propTypes = {
  surveys: PropTypes.shape().isRequired,
  surveyResults: PropTypes.shape().isRequired,
  onSetSurveyDetails: PropTypes.shape().isRequired,
};

export default KnSurveyList;
