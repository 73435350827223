/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import KnPageContent, { KnBox } from 'components/Content';
import PropTypes from 'prop-types';
import { useIsUserActive, useIsUserPending } from 'utils/utils';
import KnUserPendingCard from './UserPendingCard';
import KnPatientLists from './PatientLists';


const HomePage = (props) => {
  const { match: { params: { listType } } } = props;
  const isUserActive = useIsUserActive();
  const isUserPending = useIsUserPending();

  return (
    <KnPageContent dark={!isUserActive}>
      {isUserPending && <KnUserPendingCard />}
      {isUserActive && <KnPatientLists listType={`${listType.toUpperCase()}_PATIENTS`} />}
      <KnBox />
    </KnPageContent>
  );
};

HomePage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      listType: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default HomePage;
