/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import { styled } from '@material-ui/core/styles';
import React, {
  useCallback, useEffect, useLayoutEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import * as am4PluginsBullets from '@amcharts/amcharts4/plugins/bullets';
import {
  create, color, Container, Scrollbar, percent, useTheme, Label,
} from '@amcharts/amcharts4/core';
import {
  XYChart, ColumnSeries, LineSeries, DateAxis, ValueAxis,
} from '@amcharts/amcharts4/charts';
import {
  SEIZURE_TYPES,
  SEIZURES_COLORS,
  SYMPTOM_SEVERITY_COLORS,
  ADHERENCE_COLOR,
  ADHERENCE_COLORS,
  ADHERENCE_TYPES,
  MEDICATION_COLOR,
  REGIMEN_TYPES,
  REGIMEN_ADHERENCE_TYPES,
} from 'Constants';
import isSameDay from 'date-fns/isSameDay';
import palette from 'styles/colors';
import { translate } from 'i18n/i18n';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';
import {
  getColorByMaxOccurance,
  getColorBySeverity,
  getCombinedDefaultTimeRange,
  getMetricsDefaultTimeRange,
} from 'utils/metrics';
import { Box } from '@material-ui/core';
import { endOfMonth, startOfMonth } from 'date-fns';
import { styleChartScroll } from './styles';
import KnDateRangePicker from '../../../components/DateRangePicker';

const labelsColor = color(palette.brownishGrey);
const dateAxisLabelsFontSize = 14;
const valueAxisLabelsFontSize = 11;
const dateAxisMonthLabelFormat = 'MMM';
const dateAxisDayLabelFormat = 'MM/dd';

const tooltipLabel = color(palette.black.black1);
const tooltipBackground = color(palette.white.white2);
const tooltipFontSize = 12;
const bulletStroke = color(palette.white.white2);

const sideEffectsChartBaseGrid = color(palette.copper);
const adherenceChartBaseGrid = ADHERENCE_COLOR;
const medicationChartBaseGrid = MEDICATION_COLOR;
const columnsContainerOutlineColor = palette.coolGrey.coolGrey2;

const maxBulletSize = 25;
const minBulletSize = 15;

const maxDiamondBulletSize = 18;
const minDimondBulletSize = 12;

const chartId = 'detailed-metrics-chart';

const chartFontFamily = 'DIN Next LT W01 Regular';

const bulletChartTooltipStyle = [
  `background-color: ${tooltipBackground}`,
  `color: ${tooltipLabel}`,
  'padding: 12px 16px',
  'min-width: 163px',
].join(';');
const tooltipSectionStyle = 'padding-bottom: 8px';

/**
 * Amcharts dimensions are relative to the chart container.
 * Define actual sizes here.
 */
const KnInsightsMetricsChartContainer = styled('div')(({ theme }) => ({
  height: 360,
  width: 'calc(100vw - 492px)px',
  marginTop: theme.spacing(1),

  '& g > g:last-child rect': {
    strokeWidth: 1,
    stroke: columnsContainerOutlineColor,
    strokeOpacity: 0.3,
  },
}));

/**
 * Because the chart data is grouped, depending on the zoom level, the data might
 * either be stored as a list in the groupDataItems property OR as a single data
 * item in the dataContext property. This function makes sure we access the data
 * in a safe manner.
 *
 * @param {object} dataItem Chart tooltip data item object which will have:
 *    - groupDataItems: a list of data items when the chart element it refers to
 *      has more than one data item to represent (eg. more side effects in a day
 *      OR the chart is zoomed out in month view)
 *    - dataContext: data associated with the only data item for this chart element.
 *
 * @returns {array} The data context list from a tooltip data item.
 */
const getTooltipDataContext = (dataItem) => {
  if (dataItem.groupDataItems) {
    return _.map(dataItem.groupDataItems, (item) => item.dataContext);
  }
  return dataItem.dataContext ? [dataItem.dataContext] : [];
};

/**
 * Helper function which either returns the date as month + year if the data is month grouped
 * or the day of the month.
 *
 * Note: there is no direct way of knowing if the data is grouped. Checking only if groupDataItems
 * is defined for the dataItem is not enough as even in day view, the prop exists when there are
 * more than one entries represented (eg. multiple side-effects in one day).
 * In order to decide if the data is aggregated for a month, we check on the tooltip data if there
 * is any entry with a different date than the date the target element has, which is dateX.
 *
 * @param {object} dataItem Chart tooltip data item object.
 */
const getTooltipDate = (dataItem) => {
  const { dateX } = dataItem;
  const tooltipDataContext = getTooltipDataContext(dataItem);
  const isMonthGroup = _.find(
    tooltipDataContext,
    (item) => !isSameDay(item.date, dateX),
  );
  return isMonthGroup ? '{dateX.formatDate(\'MMM yyyy\')}' : '{dateX.formatDate(\'MM/dd\')}';
};

const createChartContainer = () => {
  const container = create(chartId, Container);
  container.fixedWidthGrid = false;
  container.width = percent(100);
  container.height = percent(100);
  container.fontFamily = chartFontFamily;

  const topLabelContainer = container.createChild(Container);
  topLabelContainer.layout = 'absolute';
  topLabelContainer.toBack();
  topLabelContainer.width = percent(100);

  const topLabel = topLabelContainer.createChild(Label);
  topLabel.text = translate('PATIENT_RECORD.sideEffectsInsights.graphTitle');
  topLabel.x = 46;
  topLabel.y = 63;
  topLabel.fontFamily = chartFontFamily;
  topLabel.fill = labelsColor;
  topLabel.fontSize = 12;
  topLabel.fontWeight = 500;

  const leftLabel = container.createChild(Label);
  leftLabel.isMeasured = false;
  leftLabel.x = 0;
  leftLabel.y = percent(75);
  leftLabel.text = translate('PATIENT_RECORD.seizureMetrics.title');
  leftLabel.fontFamily = chartFontFamily;
  leftLabel.fill = labelsColor;
  leftLabel.fontSize = 12;
  leftLabel.fontWeight = 500;
  leftLabel.rotation = 270;

  return container;
};

const createChart = (data, onRangeChangeEnded, startDate, endDate, hideDateLabels) => {
  const chartContainer = createChartContainer();
  const chart = chartContainer.createChild(XYChart);
  chart.width = percent(100);
  chart.height = percent(100);

  if (hideDateLabels) chart.paddingBottom = 5;

  const copyData = [...data];
  if (!data.length) {
    const label = chart.createChild(Label);
    label.text = translate('PATIENT_RECORD.seizureInsights.emptyChart');

    /** This positions absolutely the label over the chart. */
    label.isMeasured = false;
    label.x = percent(45);
    label.y = percent(70);
    label.fontFamily = chartFontFamily;
    label.fill = labelsColor;
  }

  /**
   * If we don't have entries for the start OR end date, we add
   * these so we see the full range of the chart all the time.
   * The start date is prepended in the list to preserve the
   * chronological order of the data.
   */
  if (!_.find(copyData, (item) => isSameDay(item.date, startDate))) {
    copyData.unshift({ date: startDate, y: 0 });
    copyData.push({ date: endDate, y: 0 });
  }
  if (!_.find(copyData, (item) => isSameDay(item.date, endDate))) {
    copyData.push({ date: endDate, y: 0 });
  }

  chart.data = copyData;

  /** Set the chart color list for the chart series. */
  chart.colors.list = SEIZURES_COLORS;

  /** On horizontal axis we show the timeline. */
  const dateAxis = chart.xAxes.push(new DateAxis());
  dateAxis.renderer.grid.template.location = 0;
  dateAxis.dateFormats.setKey('month', dateAxisMonthLabelFormat);
  dateAxis.dateFormats.setKey('day', dateAxisDayLabelFormat);
  dateAxis.renderer.labels.template.fontSize = dateAxisLabelsFontSize;
  dateAxis.renderer.labels.template.fill = labelsColor;
  dateAxis.groupData = true;
  dateAxis.renderer.labels.template.disabled = hideDateLabels;
  dateAxis.min = startOfMonth(startDate).getTime();
  dateAxis.max = endOfMonth(endDate).getTime();

  /**
   * The data will be grouped by months and by days.
   * The data granularity will change based on the zoom
   * level.
   */
  dateAxis.groupIntervals.setAll([
    { timeUnit: 'day', count: 1 },
    { timeUnit: 'month', count: 1 },
  ]);

  /** On vertical axis we show the seizure metrics grid. */
  const valueAxis = chart.yAxes.push(new ValueAxis());
  valueAxis.min = 0;

  /** Force integer-only axis labels */
  valueAxis.maxPrecision = 0;

  valueAxis.renderer.labels.template.fontSize = valueAxisLabelsFontSize;
  valueAxis.renderer.labels.template.fill = labelsColor;
  valueAxis.renderer.grid.template.location = 0;
  valueAxis.align = 'right';

  /** Enable range selector for horizontal axis. */
  chart.scrollbarX = new Scrollbar();
  chart.scrollbarX.margin(18, 0, 40, 0);
  chart.leftAxesContainer.layout = 'vertical';
  chart.leftAxesContainer.reverseOrder = true;
  styleChartScroll(chart);

  if (hideDateLabels) {
    chart.paddingBottom = 5;
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.rangeChangeDuration = 0;
  }
  /** Helper function for zooming with range change updates */
  const zoomToRange = (rangeStart, rangeEnd) => {
    /**
     * The boolean arguments are so that amcharts will not invoke range change events
     * and it will not play zoom animations.
     */
    const { min, max } = dateAxis;
    const distance = max - min;
    const start = (rangeStart.getTime() - min) / distance;
    const end = (rangeEnd.getTime() - min) / distance;
    dateAxis.zoom({ start, end }, true, true);
  };
  chart.zoomToRange = zoomToRange;

  /**
   * The "ready" event is invoked when container/chart and all its children are ready (drawn).
   */
  chart.events.on('ready', () => {
    const { startDate: start, endDate: end } = hideDateLabels
      ? getCombinedDefaultTimeRange()
      : getMetricsDefaultTimeRange(endDate);

    zoomToRange(start, end);
    onRangeChangeEnded({
      target: {
        minZoomed: start,
        maxZoomed: end,
      },
    });

    /** Register on event when range change event ends. */
    dateAxis.events.on('rangechangeended', onRangeChangeEnded);
  });

  /**
   * Adding our own handler to the Zoom Out button,
   * as there are inconsistencies in amCharts setting the start/end dates when clicking it
   * see KK-1623 [HCP] On zoom out of insights chart the range interval is incorrect
   * This makes sure the zooming ends on correct range
   */
  // chart.zoomOutButton.events.on('hit', () => {
  //   /**
  //    * Note: The original zoom out handler gets still called,
  //    * so we use a timer to give it time to finish it's animated zooming
  //    */
  //   setTimeout(() => {
  //     const start = startOfMonth(startDate);
  //     const end = add(endOfMonth(endDate), { days: 1 });
  //     zoomToRange(start, end);
  //   }, 500);
  // });

  chart.dateAxis = dateAxis;

  return chart;
};

const createSeries = (chart, field) => {
  const series = chart.series.push(new ColumnSeries());
  series.id = field;
  series.dataFields.valueY = field;
  series.dataFields.dateX = 'date';
  series.stacked = true;
  series.columns.template.width = percent(60);
  series.groupFields.valueY = 'sum';

  series.tooltip.label.fontSize = tooltipFontSize;
  series.tooltip.background.cornerRadius = 0;
  series.tooltip.pointerOrientation = 'down';
  series.tooltip.label.paddingLeft = 0;
  series.tooltip.label.paddingRight = 0;
  series.tooltip.label.paddingTop = 0;
  series.columns.template.tooltipHTML = '';

  series.columns.template.adapter.add('tooltipHTML', (html, target) => {
    const { dataItem } = target;
    if (dataItem) {
      const seizureData = getTooltipDataContext(dataItem);
      const date = getTooltipDate(dataItem);
      const seriesData = _.filter(seizureData, (item) => !!item[series.id]);
      const hasSingleValue = (seriesData[0][series.id] === 1) && seriesData.length === 1;

      let groupTimePeriod = [];
      let groupTriggers = [];
      let groupRecoveryTime = [];
      const groupErVisit = [];
      const groupRescueMedication = [];

      seriesData.forEach((s) => {
        const timePeriod = _(s.timePeriod[field]).groupBy('id').map(
          (time) => ({ ...time[0], count: time.length }),
        ).value();
        groupTimePeriod = groupTimePeriod.concat(timePeriod);

        if (s.triggers && s.triggers[field]) {
          const triggers = _.values(_.groupBy(s.triggers[field])).map((trigger) => ({
            value: trigger[0], count: trigger.length,
          }));
          groupTriggers = groupTriggers.concat(triggers);
        }

        if (s.recoveryTime && s.recoveryTime[field]) {
          groupRecoveryTime = groupRecoveryTime.concat(s.recoveryTime[field]);
        }

        if (s.didGoToER && !_.isUndefined(s.didGoToER[series.id])) {
          const erVisit = s.didGoToER[series.id]
            ? translate('PATIENT_RECORD.seizureInsights.tooltip.ERVisit')
            : translate('PATIENT_RECORD.seizureInsights.tooltip.noERVisit');
          groupErVisit.push(erVisit);
        }

        if (s.didUseRescueMedication && !_.isUndefined(s.didUseRescueMedication[series.id])) {
          const rescueMedication = s.didUseRescueMedication[series.id]
            ? translate('PATIENT_RECORD.seizureInsights.tooltip.rescueMedication')
            : translate('PATIENT_RECORD.seizureInsights.tooltip.noRescueMedication');
          groupRescueMedication.push(rescueMedication);
        }
      });

      const recoveryList = _.values(
        _.groupBy([...groupRecoveryTime, ...groupErVisit, ...groupRescueMedication]),
      )
        .map((recoveryTime) => ({
          value: recoveryTime[0], count: recoveryTime.length,
        }));

      const shouldExpandHorizontally = recoveryList.length + groupTriggers.length > 3;
      const expandStyle = shouldExpandHorizontally ? 'display: inline-flex; flex-direction: column; flex-wrap: wrap; width: 300px; max-height: 260px;' : '';

      return (`
        <div style="${expandStyle}${bulletChartTooltipStyle}">
          <div style="${tooltipSectionStyle}">
            <strong>${translate('PATIENT_RECORD.seizureInsights.tooltip.date').toUpperCase()}</strong>
            <div>${date}</div>
          </div>
          <div style="${tooltipSectionStyle}">
            <strong>${translate('PATIENT_RECORD.seizureInsights.tooltip.count').toUpperCase()}</strong>
            <div>{valueY} ${translate(`PATIENT_RECORD.seizureInsights.tooltip.seizure${hasSingleValue ? '' : '_plural'}`)}</div>
            <ul style="padding:0 0 0 20px;margin:0;">
              ${groupTimePeriod.map((t) => `<li>${t.value}${hasSingleValue ? '' : ` (${t.count})`}</li>`).join('')}
            </ul>
          </div>
          <div style="${tooltipSectionStyle}">
            <strong>${translate('PATIENT_RECORD.seizureInsights.tooltip.type').toUpperCase()}</strong>
            <div>
              ${translate(`PATIENT_RECORD.seizureInsights.chartLegend.${series.id}`).replace(/0/g, 'div')}
            </div>
          </div>
          ${groupTriggers.length ? `<div style="${tooltipSectionStyle}">
            <strong>${translate('PATIENT_RECORD.seizureInsights.tooltip.triggers').toUpperCase()}</strong>
            ${groupTriggers.map((t) => `<div>${t.value}${hasSingleValue ? '' : ` (${t.count})`}</div>`).join('')}
          </div>` : ''}
           ${recoveryList.length ? `<div style="${tooltipSectionStyle}">
            <strong>${translate('PATIENT_RECORD.seizureInsights.tooltip.recovery').toUpperCase()}</strong>
            ${recoveryList.map((r) => `<div>${r.value}${hasSingleValue ? '' : ` (${r.count})`}</div>`).join('')}
          </div>` : ''}
        </div>
      `);
    }
    return '';
  });
  return series;
};

/**
 * Creates and adds a line series to the chart instance.
 *
 * @param {object} chart Chart instance.
 * @param {array} data The list of series data source.
 * @param {string} name Name of the series.
 * @param {object} colors Object of series specific colors.
 * @param {Function} tooltipAdapter Adapter function for the tooltip content.
 * @param {Function} bulletFillAdapter Adapter function for the bullet fill color.
 */
const createLineSeries = (
  chart, data, name, colors, tooltipAdapter, bulletFillAdapter, startDate,
) => {
  const series = chart.series.push(new LineSeries());
  series.id = name;
  const valueAxis = chart.yAxes.push(new ValueAxis());
  valueAxis.align = 'right';
  valueAxis.renderer.grid.template.disabled = true;
  valueAxis.renderer.baseGrid.stroke = colors.grid;
  valueAxis.renderer.baseGrid.strokeWidth = 2;
  valueAxis.renderer.labels.template.disabled = true;

  /**
   * The line chart uses a separate valueAxis. As it
   * holds no actual data, we display no labels.
   * This will narrow the valueAxis.
   */
  valueAxis.height = 40;

  /**
   * If the series don't have any data to represent,
   * we still want to show the line. Pushing one empty
   * element will render the line chart.
   */
  if (!data.length) {
    data.push({ date: startDate, y: 0 });
  }

  series.data = data;
  series.dataFields.dateX = 'date';
  series.dataFields.valueY = 'y';
  series.dataFields.value = 'value';
  series.groupFields.value = 'sum';
  series.yAxis = valueAxis;
  series.strokeWidth = 0;

  const bullet = series.bullets.push(new am4PluginsBullets.ShapeBullet());
  bullet.propertyFields.shape = 'bullet';

  /** Set a stroke color to differentiate better bullets when they are close. */
  bullet.stroke = bulletStroke;

  /** Series basic tooltip styling. */
  series.tooltip.pointerOrientation = 'up';
  series.tooltip.background.cornerRadius = 0;
  series.tooltip.label.fontSize = tooltipFontSize;
  series.tooltip.label.fill = tooltipLabel;
  series.tooltip.label.paddingLeft = 0;
  series.tooltip.label.paddingRight = 0;
  series.tooltip.label.paddingBottom = 0;
  series.tooltip.getFillFromObject = false;
  series.tooltip.background.fill = colors.defaultTooltip;

  bullet.tooltipHTML = '';
  bullet.adapter.add('tooltipHTML', (html, target) => tooltipAdapter(target, series));

  bullet.adapter.add('fill', bulletFillAdapter);

  /**
   * The bullet size needs to be proportional
   * with the number of grouped items from the
   * data set the bullet represents. The number
   * of items is aggregated in the value
   * dataField. Here we set the min and max sizes
   * of the bullets and set logarithmic to true
   * for a better size vizualization.
   */
  series.heatRules.push({
    target: bullet,
    min: name === 'regimens' ? minDimondBulletSize : minBulletSize,
    max: name === 'regimens' ? maxDiamondBulletSize : maxBulletSize,
    property: 'size',
    dataField: 'value',
    logarithmic: true,
  });

  return series;
};

const sideEffectsTooltipAdapter = (target, series) => {
  /** Gets the set of side-effects the bullet represents. */
  if (target.dataItem) {
    const symptomsData = getTooltipDataContext(target.dataItem);
    const date = getTooltipDate(target.dataItem);

    const groupedSymptoms = _.groupBy(symptomsData, 'symptomNameValue');
    /**
     * The symptoms are displayed on separate lines, prepended with a dot
     * mapped to the color of the maximum severity for that symptom and its count.
     */
    const symptomLines = _.map(groupedSymptoms, (list, name) => {
      const severityDotColor = getColorBySeverity(list);
      return (`
        <span style="color: ${severityDotColor}">●</span>
        ${translate(
          'PATIENT_RECORD.sideEffectsInsights.symptomDetails',
          { name, count: list.length },
        )}
        <br />
      `);
    });

    /** Series tooltip fill color is depended on the maximum severity of symptoms set. */
    if (symptomsData.length) {
      /* eslint-disable-next-line no-param-reassign */
      series.tooltip.background.fill = getColorBySeverity(symptomsData);
    }

    return (`
      <div style="${bulletChartTooltipStyle}">
        <strong>
          ${translate('PATIENT_RECORD.sideEffectsInsights.title').toUpperCase()} (${date})
        </strong>
        <div>
          ${symptomLines.join('')}
        </div>
      </div>
    `);
  }
  return '';
};

const sideEffectsBulletFillAdapter = (fill, target) => {
  /** Bullet color is dependent on the maximum severity of symptoms set. */
  if (target.dataItem) {
    const symptomsData = getTooltipDataContext(target.dataItem);
    return getColorBySeverity(symptomsData);
  }
  return SYMPTOM_SEVERITY_COLORS.mild;
};

const createSideEffectsSeries = (chart, data, name, startDate) => {
  const colors = {
    grid: sideEffectsChartBaseGrid,
    defaultTooltip: SYMPTOM_SEVERITY_COLORS.severe,
  };
  createLineSeries(
    chart,
    data,
    name,
    colors,
    sideEffectsTooltipAdapter,
    sideEffectsBulletFillAdapter,
    startDate,
  );
};

const medicationBulletFillAdapter = (fill, target) => {
  if (target.dataItem) {
    const medicationData = getTooltipDataContext(target.dataItem);
    return getColorByMaxOccurance(medicationData);
  }
  return ADHERENCE_COLORS.missed;
};

const adherenceTooltipAdapter = (target, series) => {
  if (target.dataItem) {
    /** Gets the set of medications the bullet represents. */
    const adherenceData = getTooltipDataContext(target.dataItem);
    const date = getTooltipDate(target.dataItem);

    const groupedMedications = _.groupBy(adherenceData, 'type');

    const medTypes = _.uniq(adherenceData.map((s) => s.type));
    let titleKey = '';
    if (medTypes.length === 1) {
      if (medTypes[0] === ADHERENCE_TYPES.missed) {
        titleKey = 'missedTitle';
      } else if (medTypes[0] === ADHERENCE_TYPES.logged) {
        titleKey = 'loggedTitle';
      }
    } else {
      titleKey = 'title';
    }
    /**
     * The medications are displayed on separate lines, with their missed times count.
     */
    const medsLines = [];
    _.each(groupedMedications, (list) => {
      _.each(_.groupBy(list, 'medicationNameValue'), (listByName, name) => {
        const dotColor = getColorByMaxOccurance(list);
        medsLines.push(
          `<span style="color: ${dotColor}">●</span>
            ${translate('PATIENT_RECORD.medicationsInsights.medDetails', { name, count: listByName.length })}
          <br />
        `,
        );
      });
    });

    /** Series tooltip fill color is depended on the max count of regimen type. */
    if (adherenceData.length) {
      /* eslint-disable-next-line no-param-reassign */
      series.tooltip.background.fill = getColorByMaxOccurance(adherenceData);
    }

    return (`
      <div style="${bulletChartTooltipStyle}">
        <strong>
          ${translate(`PATIENT_RECORD.medicationsInsights.${titleKey}`).toUpperCase()} (${date})
        </strong>
        <div>
          ${medsLines.join('')}
        </div>
      </div>
    `);
  }
  return '';
};

const regimenTooltipAdapter = (target, series) => {
  if (target.dataItem) {
    /** Gets the set of medications the bullet represents. */
    const medicationsData = getTooltipDataContext(target.dataItem);
    const date = getTooltipDate(target.dataItem);

    const groupedMedications = _.groupBy(medicationsData, 'type');

    /**
     * The medications are displayed on separate lines, with their missed times count.
     */
    const medsLines = _.map(groupedMedications, (list, name) => {
      const dotColor = getColorByMaxOccurance(list);
      return (
        `<span style="color:${dotColor};font-size:20px;">&#9670;</span>
        ${translate(
          'PATIENT_RECORD.medicationsInsights.medDetails',
          { name: translate(`PATIENT_RECORD.medicationsInsights.chartLegend.${name}`), count: list.length },
        )
        }
        <br />
        `);
    });

    /** Series tooltip fill color is depended on the max count of regimen type. */
    if (medicationsData.length) {
      /* eslint-disable-next-line no-param-reassign */
      series.tooltip.background.fill = getColorByMaxOccurance(medicationsData);
    }

    return (`
      <div style="${bulletChartTooltipStyle}">
        <strong>
          ${translate('PATIENT_RECORD.medicationsInsights.regimenTitle').toUpperCase()} (${date})
        </strong>
        <div>
          ${medsLines.join('')}
        </div>
      </div>
    `);
  }
  return '';
};

const createMedicationsAdherenceSeries = (chart, data, name, startDate) => {
  const colors = {
    grid: name === 'adherence' ? adherenceChartBaseGrid : medicationChartBaseGrid,
    defaultTooltip: ADHERENCE_COLOR,
  };

  const tooltipAdapter = name === 'adherence' ? adherenceTooltipAdapter : regimenTooltipAdapter;

  createLineSeries(
    chart,
    data,
    name,
    colors,
    tooltipAdapter,
    medicationBulletFillAdapter,
    startDate,
  );
};

const KnInsightsMetricsChart = (props) => {
  const {
    seizureData,
    symptomsData,
    symptomsFilters,
    adherenceFilters,
    symptomsManuallyUnchecked,
    medicationsData,
    regimentFilters,
    regimenManuallyUnchecked,
    updateRanges,
    toggledSeizure,
    startDate,
    endDate,
    hideDateLabels,
  } = props;
  const chart = useRef(null);
  const rangeStart = useRef(null);
  const rangeEnd = useRef(null);
  const filterlength = symptomsFilters.length;
  const regimenFilterLength = regimentFilters.length;
  const adherenceFilterLength = adherenceFilters.length;

  const onRangeChangeEnded = useCallback(({ target }) => {
    const minZoomed = new Date(target.minZoomed);
    const maxZoomed = new Date(target.maxZoomed);

    rangeStart.current = minZoomed;
    rangeEnd.current = maxZoomed;
    updateRanges(minZoomed, maxZoomed);
  }, [updateRanges]);

  useTheme(am4themesAnimated);

  useLayoutEffect(() => {
    chart.current = createChart(
      seizureData,
      onRangeChangeEnded,
      startDate,
      endDate,
      hideDateLabels,
    );

    createSeries(chart.current, SEIZURE_TYPES.ca);
    createSeries(chart.current, SEIZURE_TYPES.cna);
    createSeries(chart.current, SEIZURE_TYPES.cu);
    createSeries(chart.current, SEIZURE_TYPES.nca);
    createSeries(chart.current, SEIZURE_TYPES.ncna);
    createSeries(chart.current, SEIZURE_TYPES.ncu);
    createSeries(chart.current, SEIZURE_TYPES.ua);
    createSeries(chart.current, SEIZURE_TYPES.una);
    createSeries(chart.current, SEIZURE_TYPES.ucua);
    createSeries(chart.current, SEIZURE_TYPES.aura);

    createMedicationsAdherenceSeries(
      chart.current,
      _.orderBy(medicationsData.filter((s) => REGIMEN_TYPES.includes(s.type)), ['date'], ['asc']),
      'regimens',
      startDate,
    );
    createMedicationsAdherenceSeries(
      chart.current,
      _.orderBy(medicationsData.filter((s) => REGIMEN_ADHERENCE_TYPES.includes(s.type)), ['date'], ['asc']),
      'adherence',
      startDate,
    );
    createSideEffectsSeries(
      chart.current,
      _.orderBy(symptomsData, ['date'], ['asc']),
      'symptoms',
      startDate,
    );

    return () => {
      chart.current.dispose();
    };
  }, [endDate, hideDateLabels, medicationsData,
    onRangeChangeEnded, seizureData, startDate, symptomsData]);

  useEffect(() => {
    if (toggledSeizure.type && chart.current) {
      const series = chart.current.map.getKey(toggledSeizure.type);

      if (series) {
        if (series.isHiding || series.isHidden) {
          series.show();
        } else {
          series.hide();
        }
      }
    }
  }, [toggledSeizure]);

  useEffect(() => {
    if (chart.current) {
      const series = chart.current.map.getKey('symptoms');
      if (series && series.isReady()) {
        if (series.isHiding || series.isHidden) {
          series.show();
        }
        const data = symptomsData.filter(
          (s) => symptomsFilters.includes(s.type) && !symptomsManuallyUnchecked[s.type],
        );
        if (!data.length) {
          series.hide();
        } else {
          setTimeout(() => {
            series.addData(data, series.data.length);
            series.appear();
          }, 100);
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterlength]);

  useEffect(() => {
    if (chart.current) {
      const series = chart.current.map.getKey('adherence');
      if (series && series.isReady()) {
        if (series.isHiding || series.isHidden) {
          series.show();
        }
        let data = medicationsData.filter(
          (s) => REGIMEN_ADHERENCE_TYPES.includes(s.type),
        );
        data = data.filter(
          (s) => adherenceFilters.includes(s.type) && !regimenManuallyUnchecked[s.type],
        );
        if (!data.length) {
          series.hide();
        } else {
          setTimeout(() => {
            series.addData(data, series.data.length);
            series.appear();
          }, 100);
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adherenceFilterLength]);

  useEffect(() => {
    if (chart.current) {
      const series = chart.current.map.getKey('regimens');
      if (series && series.isReady()) {
        if (series.isHiding || series.isHidden) {
          series.show();
        }
        let data = medicationsData.filter(
          (s) => REGIMEN_TYPES.includes(s.type),
        );
        data = data.filter(
          (s) => regimentFilters.includes(s.type) && !regimenManuallyUnchecked[s.type],
        );
        if (!data.length) {
          series.hide();
        } else {
          setTimeout(() => {
            series.addData(data, series.data.length);
            series.appear();
          }, 100);
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regimenFilterLength]);

  const handleRangeChange = (range) => {
    if (range[0] && range[1]) {
      chart.current.zoomToRange(...range);
    }
  };

  return (
    <>
      {!!chart.current && (
      <Box display="flex" justifyContent="flex-end" paddingRight={2}>
        <KnDateRangePicker
          value={[rangeStart.current, rangeEnd.current]}
          onChange={handleRangeChange}
          minDate={chart.current.dateAxis.min}
          maxDate={chart.current.dateAxis.max}
        />
      </Box>
      )}
      <KnInsightsMetricsChartContainer id={chartId} />
    </>
  );
};

KnInsightsMetricsChart.defaultProps = {
  symptomsData: null,
  medicationsData: null,
  symptomsFilters: [],
  symptomsManuallyUnchecked: {},
  regimenManuallyUnchecked: {},
  regimentFilters: [],
  adherenceFilters: [],
  hideDateLabels: false,
};

KnInsightsMetricsChart.propTypes = {
  seizureData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  symptomsData: PropTypes.arrayOf(PropTypes.shape({})),
  symptomsFilters: PropTypes.arrayOf(PropTypes.shape({})),
  adherenceFilters: PropTypes.arrayOf(PropTypes.shape({})),
  symptomsManuallyUnchecked: PropTypes.shape({}),
  regimenManuallyUnchecked: PropTypes.shape({}),
  regimentFilters: PropTypes.arrayOf(PropTypes.shape({})),
  medicationsData: PropTypes.arrayOf(PropTypes.shape({})),
  toggledSeizure: PropTypes.shape({ type: PropTypes.string.isRequired }).isRequired,
  // toogleAdherece: PropTypes.shape({ type: PropTypes.string.isRequired }).isRequired,
  updateRanges: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  hideDateLabels: PropTypes.bool,
};

export default KnInsightsMetricsChart;
