/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

/**
 * Component for displaying a button.
 */
const KnButton = (props) => {
  const {
    LhsIcon,
    children,
    color,
    onClick,
    route,
    routeParams,
    ...rest
  } = props;
  const history = useHistory();

  const onClickHandler = useCallback((e) => {
    if (route) {
      history.push(route, routeParams);
    }
    onClick(e);
  }, [onClick, route, routeParams, history]);

  return (
    <Button
      color={color}
      variant="contained"
      size="large"
      onClick={onClickHandler}
      {...rest}
    >
      {LhsIcon && (
        <Box pr={0.5} display="flex" alignItems="center">
          <LhsIcon color={color} />
        </Box>
      )}
      {children}
    </Button>
  );
};

KnButton.defaultProps = {
  LhsIcon: null,
  color: 'primary',
  onClick: () => {},
  route: null,
  routeParams: {},
  type: 'submit',
};

KnButton.propTypes = {
  /** Icon to prepend the button label. */
  LhsIcon: PropTypes.func,
  /** Content to display inside the button. */
  children: PropTypes.node.isRequired,
  /** Color variant. */
  color: PropTypes.string,
  /** Click handler. */
  onClick: PropTypes.func,
  /** Application page route if the button should perform navigation. */
  route: PropTypes.string,
  /** Route parameters passed if the button should perform navigation. */
  routeParams: PropTypes.shape(),
  /** Type of button: submit or button. */
  type: PropTypes.string,
};

export default KnButton;
