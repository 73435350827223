/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useCognitoUser from 'utils/cognitoUser';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import KnAccountMenu from 'components/AccountMenu';
import userActions from 'redux/actions/userActions';
import { styled } from '@material-ui/core/styles';
import palette from 'styles/colors';
import { APP_PAGE_URLS } from 'Constants';
import { useIsUserActive } from 'utils/utils';
import logo from '../assets/images/nileLogo84x48.png';
import KnLink from './Link';

const navLinkColor = palette.slateGrey;
const navLinkHoverColor = palette.primary;

const KnToolbar = styled(Toolbar)({
  paddingInlineStart: '120px',
  paddingInlineEnd: '90px',
});

export const KnNavigationWrapper = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(4),

  '& .MuiLink-button': {
    paddingLeft: theme.spacing(4),
    fontWeight: 'bold',
    color: navLinkColor,

    '&:hover': {
      color: navLinkHoverColor,
    },
  },
}));

const KnNavigation = () => {
  const { t: translate } = useTranslation();

  return (
    <KnNavigationWrapper display="flex" pt="25px">
      <KnLink
        underline="none"
        data-testid="nav-link-patients"
        label={translate('MAIN_NAVIGATION.patientList')}
        route={APP_PAGE_URLS.patientList.replace(':listType', 'verified')}
      />
      <KnLink
        underline="none"
        data-testid="nav-link-titrarions"
        label={translate('MAIN_NAVIGATION.templates')}
        route={APP_PAGE_URLS.titrationsList}
      />
    </KnNavigationWrapper>
  );
};

const KnHeader = () => {
  const { registering } = useSelector((state) => state.register);
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const cognitoUser = useCognitoUser();
  const isUserActive = useIsUserActive();
  const currentUserStore = useSelector((state) => state.user.currentUser);

  const isLoggedIn = !!cognitoUser && !registering;

  useEffect(() => {
    setTimeout(() => {
      if (isLoggedIn) {
        dispatch(userActions.getCurrentUser());
      }
    }, 1000);
  }, [dispatch, isLoggedIn, registering]);

  return (
    <AppBar position="sticky" color="inherit" elevation={0}>
      <KnToolbar>
        <Box display="flex">
          <img src={logo} width="84" height="48" alt={translate('GENERAL.projectName')} />

          {isLoggedIn && isUserActive && (
            <KnNavigation />
          )}
        </Box>
        {isLoggedIn && (isUserActive || currentUserStore.error) && (
          <Box>
            <KnAccountMenu />
          </Box>
        )}
      </KnToolbar>
    </AppBar>
  );
};

export default KnHeader;
