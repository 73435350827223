/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import React, {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import KnValidatedTextField from 'components/ValidatedTextField';
import { KnContrastTextField, KnUnlabeledTextField } from 'components/TextField';
import { KnSubtleText, KnHighlightTextDosage, KnCardsSubtitle } from 'components/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import { styled } from '@material-ui/core/styles';
import {
  TITRATION_DAYS_DURATION,
  TITRATION_MAX_FREQUENCY,
  TITRATION_DOSAGE_FORMAT,
  MEDICATION_UNITS,
} from 'Constants';
import {
  withKeyNamespace,
  usePrevious,
  padIfDigit,
  calculateTabletCount,
  tabletCountDisplay,
} from 'utils/utils';
import refDataService, { REFERENCE_DATA_TYPES } from 'services/referenceDataService';
import Box from '@material-ui/core/Box';
import KnDeleteIcon from 'components/icons/DeleteIcon';
import { KnElevatedBrightBox } from 'styles/common';
import palette from 'styles/colors';
import {
  KnMedicationFieldsBox,
  KnTimesFieldsBox,
  KnTimesDosagesBox,
  KnCustomDosageFormControlLabel,
  KnDeleteDosageButton,
  KnReorderDosageIcon,
} from '../styles';

const deleteIconColor = palette.aquaBlue;
const { blurBlue } = palette;

const FREQUENCIES = _.range(1, TITRATION_MAX_FREQUENCY + 1);

const KnElevatedBrightBoxChange = styled(KnElevatedBrightBox)(({ dosage }) => ({
  opacity: dosage.complete ? 0.9 : 1,
  pointerEvents: dosage.complete ? 'none' : 'all',
  background: dosage.complete ? blurBlue : 'none',
}));

const KnBoxChanged = styled(Box)(({ med }) => ({
  opacity: med.medStrength ? 0.5 : 1,
  pointerEvents: med.medStrength ? 'none' : 'all',
  marginLeft: '85px',
  marginTop: '-32px',
}));

const KnContrastTextFieldUnitchange = styled(KnContrastTextField)(({ med }) => ({
  marginLeft: '-153px',
  marginTop: '70px',
  opacity: med.medicationStrengthSet ? 0.6 : 1,
  pointerEvents: med.medicationStrengthSet ? 'none' : 'all',
}));

const dosageTimes = refDataService.getList(REFERENCE_DATA_TYPES.dosageTimePeriod);

const makeTimeItem = (id, index) => ({
  index,
  timePeriod: {
    id,
    value: refDataService.getValue(
      REFERENCE_DATA_TYPES.dosageTimePeriod,
      id,
    ),
  },
});

const makeTimeItems = (list) => _.map(list, makeTimeItem);

const stableDataTestIds = {
  quantity: 'quantity-input-field',
  duration: 'duration-input-field',
  frequency: 'frequency-input-field',
  unit: 'unit-input-field',
};

const i18nKey = withKeyNamespace('TITRATIONS.titrationBuilder');

const KnCustomDosageFormControlLabelChange = styled(KnCustomDosageFormControlLabel)({
  marginLeft: '10px', position: 'relative', top: 0,
});

/**
 * Component used by the KnTitrationFormMedication,
 * for adding/editing dosages of a medication
 */

const KnTitrationFormDosage = (props) => {
  const { t: translate } = useTranslation();
  const {
    dosage,
    unit,
    medicationStrength,
    medicationType,
    medicationStrengthSet,
    onChange,
    onRemove,
    index,
    dragHandleProps,
    ...rest
  } = props;

  const { triggerValidation, setValue, ...formValidationControls } = rest;

  const previousCustomDosage = usePrevious(dosage.hasCustomDosage);

  const getCustomDosagesKeys = useCallback((exceptIndex) => {
    const frequency = formValidationControls.control.getValues(`medicationFrequency-${dosage.keyId}`);
    const indexes = !_.isUndefined(exceptIndex)
      ? _.range(0, frequency).filter((i) => i !== exceptIndex)
      : _.range(0, frequency);
    if (frequency) {
      return indexes.map((doseIndex) => `customDosage-${dosage.keyId}-${doseIndex}`);
    }
    return null;
  }, [dosage.keyId, formValidationControls.control]);

  useEffect(() => {
    setValue(`dosageQuantity-${dosage.keyId}`, dosage.quantity);
    _.each(dosage.individualQuantities, (quantity, quantityIndex) => {
      setValue(`customDosage-${dosage.keyId}-${quantityIndex}`, quantity);
    });
  }, [
    dosage.keyId, dosage.quantity, dosage.individualQuantities,
    dosage.hasCustomDosage, setValue,
  ]);

  useEffect(() => {
    if (previousCustomDosage && !dosage.hasCustomDosage) {
      const hasCustomDosagesErrors = _.find(formValidationControls.errors, (e, key) => (
        _.startsWith(key, `customDosage-${dosage.keyId}`)
      ));
      /**
       * If custom dosages was turned off and there are custom dosages errors,
       * revalidate the fields as prepopulation might have fixed the errors.
       */
      if (hasCustomDosagesErrors) {
        triggerValidation(getCustomDosagesKeys());
      }
    }
  }, [
    dosage.hasCustomDosage, formValidationControls.errors, dosage.keyId,
    getCustomDosagesKeys, triggerValidation, previousCustomDosage,
  ]);

  /** This adds the dosage unit at the end of the text field. */
  const InputProps = useMemo(() => ({
    endAdornment: (
      <InputAdornment position="end">
        {unit}
      </InputAdornment>
    ),
  }), [unit]);

  const onTimeChange = useCallback(({ target: { value } }, position) => {
    const times = [...dosage.times];
    times[position] = makeTimeItem(value, position);
    onChange(dosage.keyId, { times });
    return value;
  }, [dosage.keyId, dosage.times, onChange]);

  const onIndividualQuantityChange = useCallback(({ target: { value } }, position) => {
    const individualQuantities = [...dosage.individualQuantities];
    individualQuantities[position] = value;
    onChange(dosage.keyId, { individualQuantities });
    return value;
  }, [dosage.keyId, dosage.individualQuantities, onChange]);

  const onIndividualQuantityBlur = useCallback((freqIndex) => {
    const hasQuantityErrors = _.find(formValidationControls.errors, (e, key) => (
      _.startsWith(key, `dosageQuantity-${dosage.keyId}`)
    ));

    /** Revalidate the other custom dosages */
    const customDosagesKeys = getCustomDosagesKeys(freqIndex);
    triggerValidation(customDosagesKeys);

    /**
     * On custom dosage blur event, if there is a total quantity error,
     * trigger validation for it as the error might have been fixed with
     * custom dosage updates.
     */
    if (hasQuantityErrors) {
      triggerValidation(`dosageQuantity-${dosage.keyId}`);
    }
  }, [formValidationControls.errors, dosage.keyId, triggerValidation, getCustomDosagesKeys]);

  const onQuantityBlur = useCallback(() => {
    const hasCustomDosagesErrors = _.find(formValidationControls.errors, (e, key) => (
      _.startsWith(key, `customDosage-${dosage.keyId}`)
    ));
    /**
     * On total quantity blur, if there are custom dosages errors,
     * trigger validation for them as the errors might have been
     * fixed with the quantity update.
     */
    if (hasCustomDosagesErrors) {
      triggerValidation(getCustomDosagesKeys());
    }
  }, [dosage.keyId, formValidationControls.errors, getCustomDosagesKeys, triggerValidation]);

  const onCustomDosageChange = useCallback(({ target: { checked } }) => {
    if (checked) {
      onChange(dosage.keyId,
        {
          quantity: _.sum(dosage.individualQuantities),
          hasCustomDosage: checked,
          individualQuantities: dosage.individualQuantities,
        });
    } else {
      const individualQuantities = [...dosage.individualQuantities];
      _.fill(individualQuantities, 0);
      onChange(dosage.keyId, { quantity: 0, hasCustomDosage: checked, individualQuantities });
    }
  }, [dosage.individualQuantities, dosage.keyId, onChange]);

  const onFieldsChange = useCallback(({ target: { value } }, fieldName) => {
    /** Update times options */
    let times;
    if (fieldName === 'frequency') {
      if (!dosage.frequency) {
        /** Prefill times on first selection */
        switch (value) {
          case 1:
            times = makeTimeItems([FREQUENCIES[0]]);
            break;
          case 2:
            times = makeTimeItems([FREQUENCIES[0], FREQUENCIES[2]]);
            break;
          case 3:
            times = makeTimeItems([FREQUENCIES[0], FREQUENCIES[1], FREQUENCIES[2]]);
            break;
          case 4:
            times = makeTimeItems(FREQUENCIES);
            break;
          default:
            break;
        }
      } else if (dosage.frequency > value) {
        /** Remove unused times, if frequency changed to lower value */
        times = _.slice(dosage.times, 0, value);
      }
    }
    if (times) {
      onChange(dosage.keyId, { [fieldName]: value, times });
    } else {
      onChange(dosage.keyId, { [fieldName]: value });
    }
    return value;
  }, [dosage.frequency, dosage.keyId, dosage.times, onChange]);

  const validateTimes = useCallback((timeIndex, value) => {
    const lhsKey = `medicationTimes-${dosage.keyId}-${timeIndex - 1}`;
    const rhsKey = `medicationTimes-${dosage.keyId}-${timeIndex + 1}`;
    const { [lhsKey]: lhs, [rhsKey]: rhs } = formValidationControls.control.getValues([
      lhsKey,
      rhsKey,
    ]);

    /**
     * If there is only one time selection, then we return true.
     * The options are prepopulated, but if this will change, no
     * selection will be captured with the required rule.
     */
    if (!lhs && !rhs) {
      return true;
    }

    /**
     * We validate if the value is in the correct order relative
     * to its left and right siblings. If an element does not
     * have a left or right sibling, we'll compare only with its
     * existing sibling.
     */
    return ((lhs ? (lhs < value) : true) && (rhs ? (rhs > value) : true));
  }, [dosage.keyId, formValidationControls.control]);

  const onTimesBlur = useCallback((timeIndex) => {
    /** On a time field blur, we validate its siblings in case a misordering was fixed. */
    const lhsKey = `medicationTimes-${dosage.keyId}-${timeIndex - 1}`;
    const rhsKey = `medicationTimes-${dosage.keyId}-${timeIndex + 1}`;
    const fields = [];

    if (formValidationControls.control.getValues(lhsKey)) {
      fields.push(lhsKey);
    }

    if (formValidationControls.control.getValues(rhsKey)) {
      fields.push(rhsKey);
    }

    if (fields.length) {
      triggerValidation(fields);
    }
  }, [dosage.keyId, formValidationControls.control, triggerValidation]);

  const timesRules = useMemo(() => _.range(0, TITRATION_MAX_FREQUENCY).map((freqIndex) => ({
    validate: {
      order: validateTimes.bind(null, freqIndex),
    },
  })), [validateTimes]);

  const validateCustomDosages = useRef(() => {
    const dosagesKeys = getCustomDosagesKeys();
    if (dosagesKeys) {
      const customDosages = _.map(
        dosagesKeys,
        (key) => formValidationControls.control.getValues(key),
      );
      return (_.sumBy(Object.values(customDosages), (d) => (Number(d) || 0)) <= 10000);
    }
    return true;
  });

  const dosageRules = useRef({
    validate: {
      customDosagesSum: validateCustomDosages.current,
    },
  });

  const disableErrorMessageProvide = _.find(formValidationControls.errors, (error, key) => (
    key.includes(dosage.keyId) && error.type === 'required'
  ));

  const hasMisorderedTimes = _.find(formValidationControls.errors, (error, key) => (
    _.startsWith(key, `medicationTimes-${dosage.keyId}`) && (error.type === 'order')
  ));

  const hasCustomDosagesAmountErrors = _.find(formValidationControls.errors, (error, key) => (
    _.startsWith(key, `customDosage-${dosage.keyId}`) && (error.type === 'valueFormat')
  ));

  const hasTotalCustomDosagesAmountErrors = !hasCustomDosagesAmountErrors
    && _.find(formValidationControls.errors, (error, key) => (
      _.startsWith(key, `customDosage-${dosage.keyId}`) && (error.type === 'customDosagesSum')
    ));

  return (
    <KnElevatedBrightBoxChange
      dosage={dosage}
      key={dosage.keyId}
      display="flex"
      flexDirection="column"
      position="relative"
      pt={3}
      pb={3}
      pr={4}
      pl={4}
      mb={3}
    >
      <Typography variant="h6" component={KnHighlightTextDosage}>
        {translate(i18nKey('dosageName'), { index: padIfDigit(index + 1) })}
        {dosage.complete ? '- COMPLETE'
          : (
            <KnBoxChanged med={{ medStrength: medicationStrengthSet }}>
              <KnCustomDosageFormControlLabelChange
                size="medium"
                control={(
                  <Checkbox
                    checked={dosage.hasCustomDosage}
                    onChange={onCustomDosageChange}
                    inputProps={{ 'data-testid': `custom-dosage-checkbox-${index + 1}` }}
                  />
              )}
                label={(
                  <Typography variant="body2" component={KnSubtleText}>
                    {translate(i18nKey('customDosage'))}
                  </Typography>
              )}
              />
            </KnBoxChanged>
          )}
      </Typography>


      <Box display="flex">
        <div>
          <KnMedicationFieldsBox>
            <KnValidatedTextField
              name={`dosageQuantity-${dosage.keyId}`}
              Component={KnContrastTextField}
              disabled={dosage.hasCustomDosage}
              label={dosage.hasCustomDosage ? translate('FIELD_LABELS.totalDosage') : translate('FIELD_LABELS.individualDosage')}
              disableErrorMessage
              required
              trimSpaces
              color="secondary"
              defaultValue={dosage.quantity}
              onChange={([e]) => onFieldsChange(e, 'quantity')}
              onBlur={onQuantityBlur}
              format={TITRATION_DOSAGE_FORMAT}
              dataTestId={stableDataTestIds.quantity}
              width="150px"
              {...formValidationControls}
            />

            <KnContrastTextFieldUnitchange
              med={{ medicationStrengthSet }}
              name={`medicationUnit-${dosage.keyId}`}
              select
              value={unit}
              label={translate('FIELD_LABELS.unit')}
              onChange={(e) => onFieldsChange(e, 'unit')}
              data-testid={stableDataTestIds.unit}
            >
              {MEDICATION_UNITS.map((unitValue, unitIndex) => (
                <MenuItem
                  key={unitValue}
                  value={unitValue}
                  data-testid={`titration-unit-option-${unitIndex + 1}`}
                >
                  {unitValue}
                </MenuItem>
              ))}
            </KnContrastTextFieldUnitchange>

            <KnValidatedTextField
              id={`frequency-dropdown-${index + 1}`}
              name={`medicationFrequency-${dosage.keyId}`}
              Component={KnContrastTextField}
              required
              disableErrorMessage
              select
              defaultValue={dosage.frequency}
              label={translate('FIELD_LABELS.medicationFrequency')}
              onChange={([e]) => onFieldsChange(e, 'frequency')}
              dataTestId={stableDataTestIds.frequency}
              {...formValidationControls}
              style={{ marginLeft: '-85px' }}
            >
              {FREQUENCIES.map((freq, freqIndex) => (
                <MenuItem
                  key={freq}
                  value={freq}
                  data-testid={`titration-frequency-option-${freqIndex + 1}`}
                >
                  {translate('GENERAL.frequencyTimes', { count: freq })}
                </MenuItem>
              ))}
            </KnValidatedTextField>

          </KnMedicationFieldsBox>
        </div>

        <Box pl={4} pr={4} display="flex" flexDirection="column" alignItems="flex-start" marginLeft="-108px">
          {dosage.frequency && (
            <KnTimesFieldsBox position="relative">
              {/* <KnCustomDosageFormControlLabel
                disabled={dosage.frequency === 1}
                    // style={{right:'700px',position:'absolute'}}
                size="medium"
                control={(
                  <Checkbox
                    checked={dosage.hasCustomDosage}
                    onChange={onCustomDosageChange}
                    inputProps={{ 'data-testid': `custom-dosage-checkbox-${index + 1}` }}
                  />
                )}
                label={(
                  <Typography variant="body2" component={KnSubtleText}>
                    {translate(i18nKey('customDosage'))}
                  </Typography>
                )}
              /> */}
              {_.range(1, dosage.frequency + 1).map((time, freqIndex) => (
                <KnTimesDosagesBox display="flex" flexDirection="column" key={`times-${time}`}>
                  <KnValidatedTextField
                    name={`medicationTimes-${dosage.keyId}-${freqIndex}`}
                    Component={KnUnlabeledTextField}
                    disableLabel
                    disableErrorMessage
                    required
                    select
                    defaultValue={_.get(dosage.times[freqIndex], 'timePeriod.id') || ''}
                    onChange={([e]) => onTimeChange(e, freqIndex)}
                    onBlur={() => onTimesBlur(freqIndex)}
                    rules={timesRules[freqIndex]}
                    dataTestId={`times-input-field-${freqIndex + 1}`}
                    {...formValidationControls}
                  >
                    {dosageTimes.map((dosageTime, timesIndex) => (
                      <MenuItem
                        key={`time-option-${dosageTime.id}`}
                        value={dosageTime.id}
                        data-testid={`titration-times-option-${timesIndex + 1}`}
                      >
                        {dosageTime.title}
                      </MenuItem>
                    ))}
                  </KnValidatedTextField>
                  <KnValidatedTextField
                    name={`customDosage-${dosage.keyId}-${freqIndex}`}
                    Component={KnUnlabeledTextField}
                    defaultValue={dosage.individualQuantities[freqIndex] || ''}
                    onChange={([e]) => onIndividualQuantityChange(e, freqIndex)}
                    onBlur={() => onIndividualQuantityBlur(freqIndex)}
                    disabled={!dosage.hasCustomDosage}
                    InputProps={InputProps}
                    disableLabel
                    disableErrorMessage
                    required
                    trimSpaces
                    format={TITRATION_DOSAGE_FORMAT}
                    rules={dosageRules.current}
                    dataTestId={`custom-dosage-input-field-${freqIndex + 1}`}
                    {...formValidationControls}
                  />
                  <Box marginLeft={1} marginTop={-0.5}>
                    <Typography
                      component={KnCardsSubtitle}
                      data-testid={`custom-dosage-tablet-label-${freqIndex + 1}`}
                    >
                      {tabletCountDisplay(calculateTabletCount(
                        dosage.individualQuantities[freqIndex],
                        medicationStrength,
                      ), medicationType)}
                    </Typography>
                  </Box>
                </KnTimesDosagesBox>
              ))}
              {_.range(1, 4 - dosage.frequency + 1).map((time) => (
                <KnTimesDosagesBox display="flex" flexDirection="column" key={`times-${time}`}>
                  <KnUnlabeledTextField
                    disabled
                  />
                  <KnUnlabeledTextField
                    style={{ marginTop: '-22px' }}
                    disabled
                  />
                </KnTimesDosagesBox>
              ))}
            </KnTimesFieldsBox>
          )}
          {!dosage.frequency && (
            <KnTimesFieldsBox position="relative">
              {_.times(4, (time) => (
                <KnTimesDosagesBox display="flex" flexDirection="column" key={`times-${time}`}>
                  <KnUnlabeledTextField
                    disabled
                  />
                  <KnUnlabeledTextField
                    disabled
                  />
                </KnTimesDosagesBox>
              ))}
            </KnTimesFieldsBox>
          )}
        </Box>
        <Box width="12%" marginTop="0.65%">
          <KnValidatedTextField
            name={`medicationDuration-${dosage.keyId}`}
            Component={KnContrastTextField}
            required
            disableErrorMessage
            select
            defaultValue={dosage.duration}
            label={translate('FIELD_LABELS.medicationDuration')}
            onChange={([e]) => onFieldsChange(e, 'duration')}
            dataTestId={stableDataTestIds.duration}
            {...formValidationControls}
          >
            {TITRATION_DAYS_DURATION.map((duration, durationIndex) => (
              <MenuItem
                key={duration}
                value={duration}
                data-testid={`titration-duration-option-${durationIndex + 1}`}
              >
                {translate('GENERAL.durationDays', { count: duration })}
              </MenuItem>
            ))}
          </KnValidatedTextField>
        </Box>
      </Box>

      <Box>
        {_.get(formValidationControls.errors, `dosageQuantity-${dosage.keyId}.type`) === 'valueFormat' && (
        <FormHelperText error data-testid="quantity-validation-error">
          {translate('FIELD_VALIDATION_MESSAGES.titrationDosage', { unit })}
        </FormHelperText>
        )}

        {hasMisorderedTimes && (
        <FormHelperText error data-testid="times-validation-error">
          {translate('FIELD_VALIDATION_MESSAGES.timesOrder')}
        </FormHelperText>
        )}

        {hasCustomDosagesAmountErrors && (
        <FormHelperText error data-testid="custom-dosages-amount-error">
          {translate('FIELD_VALIDATION_MESSAGES.customDosage', { unit })}
        </FormHelperText>
        )}

        {hasTotalCustomDosagesAmountErrors && (
        <FormHelperText error data-testid="total-custom-dosages-overflow-error">
          {translate('FIELD_VALIDATION_MESSAGES.titrationDosage', { unit })}
        </FormHelperText>
        )}

        {disableErrorMessageProvide && (
        <FormHelperText error data-testid="times-validation-error">
          {translate('FIELD_VALIDATION_MESSAGES.required')}
        </FormHelperText>
        )}
      </Box>

      <div {...dragHandleProps}>
        <KnReorderDosageIcon />
      </div>
      <KnDeleteDosageButton
        onClick={() => onRemove(dosage.keyId)}
        size="small"
        data-testid="delete-dosage-button"
      >
        <KnDeleteIcon color={deleteIconColor} />
      </KnDeleteDosageButton>
    </KnElevatedBrightBoxChange>
  );
};

KnTitrationFormDosage.propTypes = {
  dosage: PropTypes.shape().isRequired,
  unit: PropTypes.string.isRequired,
  medicationStrength: PropTypes.number,
  medicationType: PropTypes.string,
  medicationStrengthSet: PropTypes.bool,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  dragHandleProps: PropTypes.shape().isRequired,
};

KnTitrationFormDosage.defaultProps = {
  medicationStrength: undefined,
  medicationType: undefined,
  medicationStrengthSet: false,
};

export default KnTitrationFormDosage;
