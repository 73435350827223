/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import ACTION_TYPES from 'redux/actionTypes';
import { PATIENT_LINKS_STATUS } from 'Constants';

const DEFAULT_SORT_KEY_VERIFIED = 'lastName';
const DEFAULT_SORT_ORDER_VERIFIED = 'asc';
const DEFAULT_SORT_KEY_INVITED = 'lastName';
const DEFAULT_SORT_ORDER_INVITED = 'asc';
const DEFAULT_SORT_KEY_UNLINKED = 'lastName';
const DEFAULT_SORT_ORDER_UNLINKED = 'asc';

const initialState = {
  invited: null,
  verified: null,
  unlinked: null,
  error: false,
  /** Sorting applicable only for verified list */
  sortBy: DEFAULT_SORT_KEY_VERIFIED,
  sortDirection: DEFAULT_SORT_ORDER_VERIFIED,
};

const sortPatients = (data, sortKey, sortDirection) => _.orderBy(
  data,
  [(patient) => {
    if (typeof patient[sortKey] === 'string') {
      return patient[sortKey].toLowerCase();
    }
    return patient[sortKey];
  }],
  [sortDirection],
);

const removePatientWithLinkId = (data, linkId) => (
  _.filter(data, (patient) => (patient.linkId !== linkId))
);

const patientsListReducer = (state = initialState, action) => {
  const { data, type } = action;

  switch (type) {
    case ACTION_TYPES.PATIENT_LIST_REQUEST:
      return {
        ...state,
        error: false,
      };

    case ACTION_TYPES.PATIENT_LIST_SUCCESS:
      switch (action.status) {
        case PATIENT_LINKS_STATUS.accepted:
          return {
            ...state,
            verified: sortPatients(data, DEFAULT_SORT_KEY_VERIFIED, DEFAULT_SORT_ORDER_VERIFIED),
            sortBy: DEFAULT_SORT_KEY_VERIFIED,
            sortDirection: DEFAULT_SORT_ORDER_VERIFIED,
          };

        case PATIENT_LINKS_STATUS.invited:
          return {
            ...state,
            invited: sortPatients(data, DEFAULT_SORT_KEY_INVITED, DEFAULT_SORT_ORDER_INVITED),
            sortBy: DEFAULT_SORT_KEY_INVITED,
            sortDirection: DEFAULT_SORT_ORDER_INVITED,
          };

        case PATIENT_LINKS_STATUS.unlinked:
          return {
            ...state,
            unlinked: sortPatients(data, DEFAULT_SORT_KEY_UNLINKED, DEFAULT_SORT_ORDER_UNLINKED),
            sortBy: DEFAULT_SORT_KEY_UNLINKED,
            sortDirection: DEFAULT_SORT_ORDER_UNLINKED,
          };

        default:
          return state;
      }

    case ACTION_TYPES.PATIENT_LIST_ERROR:
      return (
        action.invited
          ? {
            ...state,
            invited: null,
            error: true,
          }
          : {
            ...state,
            verified: null,
            error: true,
          }
      );

    case ACTION_TYPES.PATIENT_ACCEPTED: {
      const acceptedPatient = _.find(
        state.invited,
        (patient) => (patient.linkId === action.linkId),
      );

      return {
        ...state,
        invited: removePatientWithLinkId(state.invited, action.linkId),
        verified: sortPatients(
          [...state.verified, acceptedPatient],
          state.sortBy,
          state.sortDirection,
        ),
      };
    }

    case ACTION_TYPES.PATIENT_REJECTED:
      return {
        ...state,
        invited: removePatientWithLinkId(state.invited, action.linkId),
      };

    case ACTION_TYPES.PATIENT_LIST_SORT: {
      const { sortKey: newSortKey, listType } = action;
      const isAsc = state.sortDirection === 'asc';
      /** Start with default order on sorting by new key
        * OR switch order if key didn't change */
      let newSortDirection = DEFAULT_SORT_ORDER_VERIFIED;
      if (newSortKey === state.sortBy) {
        newSortDirection = isAsc ? 'desc' : 'asc';
      }
      return {
        ...state,
        sortBy: newSortKey,
        sortDirection: newSortDirection,
        /** Apply sort to verified patients */
        [listType]: sortPatients(state[listType], newSortKey, newSortDirection),
      };
    }

    case ACTION_TYPES.PATIENT_UNLINK_SUCCESS: {
      return {
        ...state,
        verified: removePatientWithLinkId(state.verified, action.linkId),
      };
    }

    case ACTION_TYPES.INVITE_PATIENT_SUCCESS: {
      return {
        ...state,
        invited: [action.newPatientData, ...sortPatients(state.invited, 'dateInvited', 'desc')],
        sortBy: 'dateInvited',
        sortDirection: 'desc',
      };
    }

    default:
      return state;
  }
};

export { sortPatients };

export default patientsListReducer;
