import { color, percent } from '@amcharts/amcharts4/core';
import { styled, Typography } from '@material-ui/core';
import palette from 'styles/colors';

const startGripColor = color(palette.green.lighter);
const thumbColor = color(palette.green.lightest);

/**
 * Calculate the height of chart base on medications
 * @param {number} dataLength
 * @returns
 */
const getChartWidth = (dataLength) => {
  switch (true) {
    case dataLength === 1:
      return 284;
    case dataLength > 3 && dataLength < 5:
      return 500;
    case dataLength >= 5:
      return 600;
    default:
      return 400;
  }
};


const columnsContainerOutlineColor = palette.coolGrey.coolGrey2;

export const KnGanttChartContainer = styled('div')(({ datalength }) => ({
  height: getChartWidth(parseInt(datalength, 10)),
  width: '100%',
  '& g > g:last-child': {
    shapeRendering: 'auto', // crispEdges
  },

  '& g > g:last-child rect': {
    strokeWidth: 1,
    stroke: columnsContainerOutlineColor,
    strokeOpacity: 0.3,
  },
}));

export const KnGanntChartDateRange = styled(Typography)(({ theme }) => ({
  marginLeft: 'auto',
  marginRight: theme.spacing(3),
}));


/**
 * Custom styling for Gantt chart
 * @param {Object} chart
 * @returns
 */
/* eslint-disable no-param-reassign */
export const styleChartScroll = (chart) => {
  chart.scrollbarX.startGrip.strokeWidth = 2;
  chart.scrollbarX.endGrip.strokeWidth = 2;
  chart.scrollbarX.startGrip.background.fill = color(startGripColor);
  chart.scrollbarX.endGrip.background.fill = color(startGripColor);
  chart.scrollbarX.thumb.background.fill = color(thumbColor);

  chart.scrollbarX.startGrip.icon.stroke = color(palette.white.white2);
  chart.scrollbarX.endGrip.icon.stroke = color(palette.white.white2);
  // Applied on hover
  chart.scrollbarX.startGrip.background.states.getKey('hover').properties.fill = color(startGripColor);
  chart.scrollbarX.endGrip.background.states.getKey('hover').properties.fill = color(startGripColor);
  chart.scrollbarX.thumb.background.states.getKey('hover').properties.fill = color(thumbColor);

  // Applied on mouse down
  chart.scrollbarX.startGrip.background.states.getKey('down').properties.fill = color(startGripColor);
  chart.scrollbarX.endGrip.background.states.getKey('down').properties.fill = color(startGripColor);
  chart.scrollbarX.thumb.background.states.getKey('down').properties.fill = color(thumbColor);
  chart.scrollbarX.parent.layout = 'absolute';
  chart.scrollbarX.width = percent(90);

  chart.zoomOutButton.parent = chart.scrollbarX.parent;
  chart.zoomOutButton.background.fill = color(startGripColor);
  chart.zoomOutButton.background.states.getKey('hover').properties.fill = color(startGripColor);
  chart.zoomOutButton.background.states.getKey('down').properties.fill = color(startGripColor);
  chart.zoomOutButton.icon.strokeWidth = 4;
  chart.zoomOutButton.icon.margin(-7, 0, 0, -4);
  chart.zoomOutButton.margin(-7, 0, 10, 0);
  chart.zoomOutButton.width = 26;
  chart.zoomOutButton.height = 26;
  chart.zoomOutButton.position = 'absolute';
  chart.zoomOutButton.align = 'right';
  chart.zoomOutButton.valign = 'top';

  return chart;
};
