/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import KnPageContent from 'components/Content';
import appActions from 'redux/actions/appActions';
import registerActions from 'redux/actions/registerActions';
import loginActions from 'redux/actions/loginActions';
import { APP_PAGE_URLS } from 'Constants';
import MFAStep from 'features/login/MFAStep';
import SignUpStep from './SignUpStep';
import VerifyAccountStep from './VerifyAccountStep';
import EULAStep from './EULAStep';

const stepComponents = [SignUpStep, EULAStep, VerifyAccountStep, MFAStep];

/** Some relevant step index constants,
 * when we need to do extra actions, besides content change
 */
const SIGN_UP_STEP = 0;
const EULA_STEP = 1;
const ACCOUNT_VERIFIED_STEP = 2;
const MFA_CONFIRMED_STEP = 3;

const RegisterPage = () => {
  const dispatch = useDispatch();
  const registerStore = useSelector((state) => state.register);
  const loginStore = useSelector((state) => state.login);
  const [currentStep, setCurrentStep] = useState(0);
  /** This will accummulate all the data gathered step by step */
  const [stepsData, setStepsData] = useState({});
  const StepComponent = stepComponents[currentStep];
  const history = useHistory();

  useEffect(() => () => {
    dispatch(registerActions.reset());
  }, [dispatch]);

  /**
   * Called when a back button click on eula screen
   */
  const eulaHandleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  /**
   * Called when a card (step) succeeds
   * Note that last card navigates to the home page, and doesn't call this callback
   */
  const nextStep = (response) => {
    setStepsData({
      ...stepsData,
      ...response,
    });

    if (currentStep === ACCOUNT_VERIFIED_STEP) {
      /** Cognito user account creation completed,
       * proceed to sign in automatically, to get access token
       */
      dispatch(loginActions.signIn({
        email: stepsData.email,
        password: stepsData.password,
      }))
        .then((signInResponse) => {
          if (!signInResponse.error) {
            /** Sign in was successful,
             * save the data and proceed with the next step (MFA code confirmation) */
            setStepsData({
              ...stepsData,
              signInResult: signInResponse,
            });
            setCurrentStep(currentStep + 1);
          }
        });
      /** Note: in case of sign in error, stay on current step
       * This should be highly unlikely, as we use credentials of the account created a step before
       */
    } else if (currentStep === MFA_CONFIRMED_STEP) {
      /** Sign in confirmed with MFA code,
       * proceed to register the new account into our DB
       */
      dispatch(registerActions.registerUser({
        ...stepsData,
        eulaAgreementTimestamp: new Date(),
      }))
        .then((registerResponse) => {
          if (!registerResponse.error) {
            /** Cognito sign up, sign in, register with BE all succeeded
            * we can let the new user to the home page
            */
            history.push(APP_PAGE_URLS.patientList.replace(':listType', 'verified'));
          }
        });
    } else if (currentStep === SIGN_UP_STEP) {
      setStepsData({ ...response });
      setCurrentStep(currentStep + 1);
    } else if (currentStep === EULA_STEP) {
      dispatch(registerActions.signUp({
        email: stepsData.email,
        password: stepsData.password,
        phone: stepsData.phone,
      }))
        .then((res) => {
          if (!res.error) {
            /** Sign up was successful,
             * send all gathered data to step 2 of the sign up flow */
            setStepsData({
              ...stepsData,
              username: res.username,
            });
            setCurrentStep(currentStep + 1);
          }
        });
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  /**
   * Called when flow is cancelled
   */
  const resetFlow = () => {
    dispatch(appActions.appPopNotification());
    setCurrentStep(0);
  };

  return (
    <KnPageContent dark>
      <Card elevation={0} style={currentStep === EULA_STEP ? { maxWidth: 992, overflow: 'hidden' } : {}}>
        <CardContent>
          <StepComponent
            stepsData={stepsData}
            submitDisabled={registerStore.busy || loginStore.busy}
            onSuccess={nextStep}
            onReset={resetFlow}
            eulaHandleBack={eulaHandleBack}
          />
        </CardContent>
      </Card>
    </KnPageContent>
  );
};

export default RegisterPage;
