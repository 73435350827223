/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import { styled } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { KnBrightBox } from 'components/Content';
import KnValidatedTextField from 'components/ValidatedTextField';
import { KnContrastTextField } from 'components/TextField';
import KnButton from 'components/Button';
import MenuItem from '@material-ui/core/MenuItem';
import userActions from 'redux/actions/userActions';
import { withKeyNamespace, composeFullName } from 'utils/utils';
import patientActions from 'redux/actions/patientActions';
import FormHelperText from '@material-ui/core/FormHelperText';
import healthSystemActions from 'redux/actions/healthSystemActions';

import { EMAIL_FORMAT } from 'Constants';
import PropTypes from 'prop-types';

const i18nKey = withKeyNamespace('STUDY_PATIENTS');

const KnWrapperBox = styled(Box)({
  width: '100%',
});

const KnInLine = styled('div')({
  display: 'inline-flex',
});

const InvitePatientToolbar = ({ onInvitePatientSuccess }) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const { physiciansList } = useSelector((state) => state.user);
  const {
    handleSubmit, errors, control, formState: { isValid }, setError, getValues,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const {
    data: HSProviders,
    loading: loadingHSProviders,
  } = useSelector((state) => state.healthSystem);
  const { isAdmin: admin } = useSelector((state) => state.user.currentUser);
  const [physiciansListVariable, setPhysiciansListVariable] = useState(physiciansList);

  useEffect(() => {
    dispatch(userActions.getPhysicians());
    dispatch(healthSystemActions.fetch());
  }, [dispatch]);
  const handleDropDownChange = useCallback(([{ target: { value } }]) => {
    const physiciansListVar = physiciansList.data.filter((p) => p.healthSystemId === value);
    setPhysiciansListVariable({ ...physiciansList, data: physiciansListVar });
    return value;
  }, [physiciansList]);

  const onSubmit = useCallback(({ physicianFilter, patientEmail }) => {
    const physician = physiciansList.data.find((p) => p.userId === physicianFilter);
    const physicianFullName = `${physician.firstName} ${physician.lastName}`;
    // eslint-disable-next-line max-len
    dispatch(patientActions.inviteStudyPatient(patientEmail, physicianFilter, physicianFullName)).then(() => {
      onInvitePatientSuccess();
    }).catch((res) => {
      if (res === 409) setError('patientEmail', 'invitationExist');
    });
  }, [dispatch, onInvitePatientSuccess, physiciansList.data, setError]);

  const orderedPhysicians = useMemo(() => (
    _.orderBy(
      physiciansListVariable.data,
      [(physician) => `${physician.lastName.toLowerCase()}${physician.firstName.toLowerCase()}`],
    )
  ), [physiciansListVariable.data]);

  const patientEmail = (errors) ? errors.patientEmail : undefined;
  const errorType = (patientEmail) ? patientEmail.type : '';
  const message = (patientEmail) ? patientEmail.message : '';

  /** Use the error's message if it was provided OR use a global field validation message. */
  const helperText = errorType
    ? (message || translate(`FIELD_VALIDATION_MESSAGES.${errorType}`))
    : null;


  return (
    <KnBrightBox
      display="flex"
      flexDirection="row"
      p={2}
    >
      <KnWrapperBox>
        <form
          style={{
            width: '100%', display: 'inline-flex', justifyContent: 'space-between', height: 60,
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <KnInLine>
            <KnValidatedTextField
              Component={KnContrastTextField}
              name="patientEmail"
              control={control}
              errors={errors}
              required
              disableErrorMessage
              trimSpaces
              format={EMAIL_FORMAT}
              defaultValue=""
              maxLength={128}
              style={{ width: '300px' }}
            />

            {admin && (
            <KnValidatedTextField
              Component={KnContrastTextField}
              name="healthSystem"
              control={control}
              errors={errors}
              select
              required
              maxLength={256}
              onChange={handleDropDownChange}
              disabled={loadingHSProviders}
              style={{
                marginLeft: '11px',
                position: 'relative',
                width: '285px',
              }}
            >
              {
            HSProviders.map((option, index) => (
              <MenuItem
                key={option.id}
                value={option.id}
                data-testid={`health-system-option-${index + 1}`}
              >
                {option.name}
              </MenuItem>
            ))
          }
            </KnValidatedTextField>
            )}

            <KnValidatedTextField
              name="physicianFilter"
              // Component={KnContrastTextField}
              control={control}
              errors={errors}
              required
              // disableErrorMessage
              select
              disabled={admin && !getValues('healthSystem')}
              defaultValue=""
              label={translate('HOME.selectFilterByPhysician')}
              data-testid="physician-filter-dropdown"
              style={{
                marginLeft: '11px',
                position: 'relative',
                width: '285px',
              }}
            >
              {physiciansListVariable.initialized
                && orderedPhysicians.map((physician, index) => (
                  <MenuItem
                    key={physician.userId}
                    value={physician.userId}
                    data-testid={`physician-filter-option-${index + 1}`}
                  >
                    {composeFullName(physician.firstName, physician.lastName)}
                  </MenuItem>
                ))}
            </KnValidatedTextField>
          </KnInLine>
          <KnButton style={{ width: '200px' }} data-testid="send-invite-button" disabled={(!isValid)}>
            {translate(i18nKey('submitButton'))}
          </KnButton>
        </form>

        <FormHelperText
          error
          data-testid="field-validation-error"
        >
          {helperText}
        </FormHelperText>
      </KnWrapperBox>
    </KnBrightBox>
  );
};

InvitePatientToolbar.propTypes = {
  onInvitePatientSuccess: PropTypes.func.isRequired,
};

export default InvitePatientToolbar;
