/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { styled } from '@material-ui/core/styles';
import palette from 'styles/colors';

const actionLinkColor = palette.primary;
const disabledActionLinkColor = palette.coolGrey.coolGrey1;

const KnLink = (props) => {
  const {
    label,
    route,
    children,
    ...rest
  } = props;
  const history = useHistory();

  const onClickHandler = useCallback(() => {
    history.push(route);
  }, [history, route]);

  return (
    <Link
      component="button"
      type="button"
      variant="body1"
      color="primary"
      underline="always"
      onClick={onClickHandler}
      {...rest}
    >
      {label}
      {children}
    </Link>
  );
};

const KnActionLinkStyled = styled(KnLink)({
  alignItems: 'center',
  color: actionLinkColor,
  display: 'flex',
  fontWeight: 500,
});

/**
 * Component for displaying a bright action link.
 */
export const KnActionLink = (props) => {
  const {
    LhsIcon,
    children,
    disabled,
    ...rest
  } = props;

  return (
    <KnActionLinkStyled underline="none" disabled={disabled} {...rest}>
      {LhsIcon && (
        <Box pr={0.5} display="flex" alignItems="center">
          <LhsIcon color={disabled ? disabledActionLinkColor : actionLinkColor} />
        </Box>
      )}
      {children}
    </KnActionLinkStyled>
  );
};

KnLink.defaultProps = {
  children: null,
  label: '',
  route: '',
};

KnLink.propTypes = {
  label: PropTypes.string,
  route: PropTypes.string,
  children: PropTypes.node,
};

KnActionLink.propTypes = {
  /** Icon to prepend the link text */
  LhsIcon: PropTypes.func,
  /** Content to be displayed inside the link */
  children: PropTypes.node,
  disabled: PropTypes.bool,
};

KnActionLink.defaultProps = {
  LhsIcon: null,
  children: null,
  disabled: false,
};

export default KnLink;
