/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { appInsights } from 'appInsights';
import Box from '@material-ui/core/Box';
import KnPageContent from 'components/Content';
import patientActions from 'redux/actions/patientActions';
import {
  TRACKING_EVENTS, API_REQUEST_ERROR_CODES, PATIENT_LINKS_STATUS, APP_PAGE_URLS, API_URLS,
} from 'Constants';
import KnPatientReports from './PatientReports';
import KnPatientInfomation from './PatientInformation';
import KnInsightsMetrics from './InsightsMetrics';
import KnPatientRegimen from './MedicationRegimen';
import KnAddRegiment from './AddRegiment';
import { KnMedicationWrapper } from './styles';

const PatientRecordPage = (props) => {
  const { match: { params: { patientId } } } = props;
  const { location } = props;
  let patientType = _.get(location, 'state.patientType', '');
  const pathname = _.get(location, 'pathname', '');
  if (pathname.indexOf(API_URLS.inviteStudyPatient) > -1) {
    patientType = true;
  }
  const dispatch = useDispatch();
  const history = useHistory();
  const { isAdmin: admin } = useSelector((state) => state.user.currentUser);

  const {
    data: patientData,
    error: patientInfoError,
  } = useSelector((state) => state.patientRecord.patientInfo);
  const {
    verified,
  } = useSelector((state) => state.patientsList);

  useEffect(() => {
    appInsights.trackEvent({ name: TRACKING_EVENTS.viewPatientRecord });
  }, []);

  useEffect(() => {
    const tableInitialized = verified;
    if (!tableInitialized) {
      dispatch(patientActions.fetchPatients(
        PATIENT_LINKS_STATUS.accepted,
        false,
      ));
    }
    if (tableInitialized) {
      const email = patientData ? patientData.email : '';
      const show = _.find(verified, (c) => c.email === email);
      const isVerified = patientData ? patientData.isVerified : undefined;
      if (isVerified && show && patientType) {
        const id = show.patientId;
        history.push(
          APP_PAGE_URLS.patientRecord.replace(':patientId', id),
          { patientType: false },
        );
      }
    }
  }, [dispatch, verified, patientData, history, patientType]);

  const patientDataLoading = useMemo(
    () => !patientData && !patientInfoError,
    [patientData, patientInfoError],
  );

  return (
    <KnMedicationWrapper>
      <KnPageContent>
        <KnPatientInfomation patientId={patientId} patientType={patientType} />
        <KnAddRegiment patientId={patientId} patientType={patientType} admin={admin} />
        {!patientDataLoading && patientInfoError !== API_REQUEST_ERROR_CODES.USER_NOT_FOUND
          && (
            <>
              <Box display="flex" flex="1">
                <KnPatientReports patientId={patientId} patientType={patientType} />
                <KnInsightsMetrics patientId={patientId} patientType={patientType} />
              </Box>
              <KnPatientRegimen patientId={patientId} patientType={patientType} />
            </>
          )}
      </KnPageContent>
    </KnMedicationWrapper>
  );
};

PatientRecordPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      patientId: PropTypes.string.isRequired,
    }),
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      patientType: PropTypes.string,
    }),
  }).isRequired,
};

export default PatientRecordPage;
