/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import { REGIMEN_STATUS } from 'Constants';
import ACTION_TYPES from 'redux/actionTypes';

const initialData = {
  /** 'null' means it's not yet loaded / or error */
  data: null,
  /** usually simply 'true', when there is an error,
   * or error code, if we want to handle specific errors (eg. for patientInfo) */
  error: false,
};

const initialState = {
  patientInfo: initialData,
  seizuresMetrics: initialData,
  symptomsMetrics: initialData,
  longTermSeizureMetrics: initialData,
  longTermSymptomsMetrics: initialData,
  longTermMedicationsMetrics: initialData,
  regimens: initialData,
  thresholds: initialData,
  surveyResults: initialData,
  allsurveyResults: initialData,
  thresholdEvents: initialData,
};

const patientRecordReducer = (state = initialState, action) => {
  const { data, type, error } = action;

  switch (type) {
    case ACTION_TYPES.PATIENT_SET_INFO:
      return {
        ...state,
        patientInfo: {
          data,
          error: false,
        },
      };
    case ACTION_TYPES.PATIENT_INVITE_SET_INFO:
      return {
        ...state,
        patientInfo: {
          data,
          error: false,
        },
      };
    case ACTION_TYPES.PATIENT_ERROR:
      return {
        ...state,
        patientInfo: {
          data: null,
          error,
        },
      };
    case ACTION_TYPES.PATIENT_CLEAR_ERROR:
      return {
        ...state,
        patientInfo: {
          data: state.patientInfo.data,
          error: false,
        },
      };
    case ACTION_TYPES.PATIENT_TOPLINE_SEIZURES_REQUEST:
    case ACTION_TYPES.PATIENT_TOPLINE_SEIZURES_ERROR:
      return {
        ...state,
        seizuresMetrics: {
          data: null,
          error: (type === ACTION_TYPES.PATIENT_TOPLINE_SEIZURES_ERROR),
        },
      };
    case ACTION_TYPES.PATIENT_TOPLINE_SEIZURES_SUCCESS:
      return {
        ...state,
        seizuresMetrics: {
          data,
          error: false,
        },
      };

    case ACTION_TYPES.PATIENT_TOPLINE_SYMPTOMS_REQUEST:
    case ACTION_TYPES.PATIENT_TOPLINE_SYMPTOMS_ERROR:
      return {
        ...state,
        symptomsMetrics: {
          data: null,
          error: (type === ACTION_TYPES.PATIENT_TOPLINE_SYMPTOMS_ERROR),
        },
      };

    case ACTION_TYPES.PATIENT_TOPLINE_SYMPTOMS_SUCCESS:
      return {
        ...state,
        symptomsMetrics: {
          data,
          error: false,
        },
      };

    case ACTION_TYPES.PATIENT_LONG_TERM_SEIZURES_REQUEST:
    case ACTION_TYPES.PATIENT_LONG_TERM_SEIZURES_ERROR:
      return {
        ...state,
        longTermSeizureMetrics: {
          data: null,
          error: (type === ACTION_TYPES.PATIENT_LONG_TERM_SEIZURES_ERROR),
        },
      };

    case ACTION_TYPES.PATIENT_LONG_TERM_SEIZURES_SUCCESS:
      return {
        ...state,
        longTermSeizureMetrics: {
          data,
          error: false,
        },
      };

    case ACTION_TYPES.PATIENT_LONG_TERM_SYMPTOMS_REQUEST:
    case ACTION_TYPES.PATIENT_LONG_TERM_SYMPTOMS_ERROR:
      return {
        ...state,
        longTermSymptomsMetrics: {
          data: null,
          error: (type === ACTION_TYPES.PATIENT_LONG_TERM_SYMPTOMS_ERROR),
        },
      };

    case ACTION_TYPES.PATIENT_LONG_TERM_SYMPTOMS_SUCCESS:
      return {
        ...state,
        longTermSymptomsMetrics: {
          data,
          error: false,
        },
      };

    case ACTION_TYPES.PATIENT_LONG_TERM_MEDICATION_ADHERENCE_REQUEST:
    case ACTION_TYPES.PATIENT_LONG_TERM_MEDICATION_ADHERENCE_ERROR:
      return {
        ...state,
        longTermMedicationsMetrics: {
          data: null,
          error: (type === ACTION_TYPES.PATIENT_LONG_TERM_MEDICATION_ADHERENCE_ERROR),
        },
      };

    case ACTION_TYPES.PATIENT_LONG_TERM_MEDICATION_ADHERENCE_SUCCESS:
      return {
        ...state,
        longTermMedicationsMetrics: {
          data,
          error: false,
        },
      };

    case ACTION_TYPES.PATIENT_MEDICATION_REGIMENS_REQUEST:
    case ACTION_TYPES.PATIENT_MEDICATION_REGIMENS_ERROR:
      return {
        ...state,
        regimens: {
          data: null,
          error: (type === ACTION_TYPES.PATIENT_MEDICATION_REGIMENS_ERROR),
        },
      };
    case ACTION_TYPES.PATIENT_MEDICATION_REGIMENS_SUCCESS:
      return {
        ...state,
        regimens: {
          data,
          error: false,
        },
      };

    case ACTION_TYPES.PATIENT_MEDICATION_REGIMEN_DEACTIVATED: {
      const { regimenId } = action.data;
      return {
        ...state,
        regimens: {
          ...state.regimens,
          data: _.map(state.regimens.data, (regimen) => {
            if (regimen.id === regimenId) {
              return { ...regimen, status: REGIMEN_STATUS.deactivated, deactivatedAt: new Date() };
            }
            return regimen;
          }),
        },
      };
    }

    case ACTION_TYPES.PATIENT_THRESHOLDS_REQUEST:
    case ACTION_TYPES.PATIENT_THRESHOLDS_ERROR:
      return {
        ...state,
        thresholds: {
          data: null,
          error: (type === ACTION_TYPES.PATIENT_THRESHOLDS_ERROR),
        },
      };
    case ACTION_TYPES.PATIENT_THRESHOLDS_SUCCESS:
      return {
        ...state,
        thresholds: {
          data,
          error: false,
        },
      };

    case ACTION_TYPES.PATIENT_CLEAR_RECORD:
      return initialState;

    case ACTION_TYPES.PATIENT_SURVEYS_REQUEST:
    case ACTION_TYPES.PATIENT_SURVEYS_ERROR:
      return {
        ...state,
        surveyResults: {
          data: null,
          error: (type === ACTION_TYPES.PATIENT_SURVEYS_ERROR),
        },
      };

    case ACTION_TYPES.PATIENT_SURVEYS_SUCCESS:
      return {
        ...state,
        surveyResults: {
          data,
          error: false,
        },
      };

    case ACTION_TYPES.PATIENT_ALL_SURVEYS_REQUEST:
    case ACTION_TYPES.PATIENT_ALL_SURVEYS_ERROR:
      return {
        ...state,
        allsurveyResults: {
          data: null,
          error: (type === ACTION_TYPES.PATIENT_ALL_SURVEYS_ERROR),
        },
      };

    case ACTION_TYPES.PATIENT_ALL_SURVEYS_SUCCESS:
      return {
        ...state,
        allsurveyResults: {
          data,
          error: false,
        },
      };

    case ACTION_TYPES.PATIENT_THRESHOLD_EVENTS_REQUEST:
    case ACTION_TYPES.PATIENT_THRESHOLD_EVENTS_ERROR:
      return {
        ...state,
        thresholdEvents: {
          data: null,
          error: (type === ACTION_TYPES.PATIENT_THRESHOLD_EVENTS_ERROR),
        },
      };

    case ACTION_TYPES.PATIENT_THRESHOLD_EVENTS_SUCCESS:
      return {
        ...state,
        thresholdEvents: {
          data,
          error: false,
        },
      };

    case ACTION_TYPES.PATIENT_THRESHOLD_SEEN_SUCCESS:
      return {
        ...state,
        thresholdEvents: {
          data: state.thresholdEvents.data.map((item) => (
            { ...item, isSeen: item.id === action.thresholdId ? true : item.isSeen }
          )),
        },
      };

    default:
      return state;
  }
};

export default patientRecordReducer;
